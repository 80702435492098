<cam-card>
  <cam-card-content>
    <cam-title [level]="3">{{ this.title | translate }}</cam-title>

    <cam-quick-actions-custom
      [elementPerPage]="2"
      [swipeTemplate]="swipeTemplate"
      [elements]="this.quickActionElements"
      [slidesPerGroup]="2"
      [isFreeMode]="false"
      (onSlideChanged)="this.onSlideChanged($event)"
    ></cam-quick-actions-custom>
  </cam-card-content>
</cam-card>

<ng-template #swipeTemplate let-element="element" [typedTemplate]="typeToken">
  <div class="stat-element" (click)="element.onClick()">
    <cam-progress-circle [upTitle]="element.upTitle" [downTitle]="element.downTitle" [progress]="element.progress">
    </cam-progress-circle>
  </div>
</ng-template>
