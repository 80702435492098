import { Component } from '@angular/core';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { environment } from 'src/environments/environment';

import { BaseComponent } from '../../core/abstract/baseComponent';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent {
  get title() {
    return this._navigationService.pageName || environment.APP_NAME;
  }
  get showBack() {
    return (this._navigationService.pageLevel || EPageLevel.None) > EPageLevel.Main;
  }
  constructor() {
    super();
  }

  public goToHome() {
    this._router.navigateByUrl('/');
  }
  public goBack() {
    this._location.back();
  }
}
