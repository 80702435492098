import { Component } from '@angular/core';

import { EMainRoute, EVisitRoute } from '../../../../../models/routes/eRoutes';
import { RouteConfiguration } from '../../../../../models/routes/routeConfiguration';
import { AbstractComponent } from '../../../../core/abstract/abstractComponent';

@Component({
  selector: 'app-current-visit',
  templateUrl: './current-visit.component.html',
  styleUrls: ['./current-visit.component.scss'],
})
export class HomeCurrentVisitComponent extends AbstractComponent {
  public hasVisitInProgress: boolean = false;

  constructor() {
    super();

    this.hasVisitInProgress = !!this._navigationService.visitInProgress$.getValue();

    this._registerSubscription(
      this._navigationService.visitInProgress$.subscribe(visit => (this.hasVisitInProgress = !!visit))
    );
  }

  public goToCurrentVisit() {
    this._router.navigateByUrl(
      RouteConfiguration.getAbsoluteUrl([EMainRoute.VISIT, EVisitRoute.DETAIL], {
        id: this._navigationService.visitInProgress$.getValue()?.id,
        type: this._navigationService.visitInProgress$.getValue()?.type,
      })
    );
  }
}
