<ng-container *ngIf="this.getTrigram()">
  <cam-trigram [value]="this.getTrigram()"></cam-trigram>
</ng-container>
<ng-container *ngIf="this.getCivilityIcon()">
  <cam-local-icon [type]="this.getCivilityIcon() || this.icon.Profile" size="xs"></cam-local-icon>
</ng-container>
<ng-container *ngIf="this.haveCompanyInfo()">
  <ng-container *ngIf="this.isCompany()">
    <cam-local-icon [type]="this.icon.Company"></cam-local-icon>
  </ng-container>
</ng-container>
