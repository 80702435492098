import { Injectable } from '@angular/core';

import { compareAsc, differenceInCalendarDays } from 'date-fns';
import { BehaviorSubject, filter, map, tap } from 'rxjs';

import { CamBaseService, MappingApiType, Request } from '@camelot/server';

import { IFollowUpService } from '../IFollowUpService';
import { Reminder } from './dto/reminder';

const apiRoutes: MappingApiType = {
  GetReminders: {
    type: 'GET',
    url: '{ApiUrl}/reminders',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppRemindersService extends CamBaseService implements IFollowUpService<Reminder[]> {
  private _reminders$ = new BehaviorSubject<Reminder[]>([]);

  constructor() {
    super(apiRoutes);
  }

  public fetch() {
    return this._serverService.request<Reminder[]>(new Request({ type: 'GetReminders', cacheTime: 0 })).pipe(
      filter(data => !!data),
      tap(data => {
        this._reminders$.next(data);
      })
    );
  }

  public reminders$() {
    return this._reminders$.pipe(map(data => data.sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)))));
  }

  public above$() {
    const todayDate = new Date();
    return this.reminders$().pipe(
      map(data => data.filter(reminder => differenceInCalendarDays(new Date(reminder.date), todayDate) < 0))
    );
  }

  public today$() {
    const todayDate = new Date();
    return this.reminders$().pipe(
      map(data => data.filter(reminder => differenceInCalendarDays(new Date(reminder.date), todayDate) === 0))
    );
  }

  public ahead$() {
    const todayDate = new Date();
    return this.reminders$().pipe(
      map(data => data.filter(reminder => differenceInCalendarDays(new Date(reminder.date), todayDate) > 0))
    );
  }
}
