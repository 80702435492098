import { BehaviorSubject } from 'rxjs';

import { Menu, MenuBase } from '@camelot/menu';

export class FilterHelper {
  public refresh$ = new BehaviorSubject('');

  get filter() {
    return this._filter;
  }
  set filter(filter: string) {
    this._filter = filter;

    this.refresh$.next(this._filter);
  }
  private _filter: string = '';

  private _items: {
    label: string;
    defaultOpen: boolean;
    translationData?: {};
    options?: { notificationBadge: { label: number; style?: string } };
  }[];

  constructor(
    items: {
      label: string;
      defaultOpen: boolean;
      translationData?: object;
      options?: { notificationBadge: { label: number; style?: string } };
    }[]
  ) {
    this._items = items;
  }
  public getMenu() {
    return new Menu({
      elements: this._items.map(item => {
        const key = this._getKey(item.label);
        return new MenuBase({
          key: key,
          label: item.label,
          callback: () => (this.filter = key),
          defaultOpen: item.defaultOpen,
          translationData: item.translationData,
          options: item.options,
        });
      }),
      direction: 'responsive',
    });
  }

  public updateMenuDatas(
    data: {
      key: string;
      translationData?: object;
      options?: { notificationBadge: { label: number; style?: string } };
    }[]
  ) {
    for (const item of data) {
      const itemToModify = this._items.find(x => x.label === item.key);
      if (itemToModify) {
        itemToModify.translationData = item.translationData;
        itemToModify.options = item.options;
      }
    }
  }

  private _getKey(label: string): string {
    const lastDot = label.lastIndexOf('.');
    if (lastDot !== -1) {
      return label.substring(lastDot + 1);
    } else {
      return label;
    }
  }
}
