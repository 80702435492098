import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { VisitInProgress } from '../../services/visits/dto/visit-in-progress';

export enum EPageLevel {
  None = 0,
  Main = 1,
  Sub = 2,
  Details = 3,
}

export type PageInformation = {
  level: EPageLevel;
  name?: string;
};

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public pageLevel!: EPageLevel;
  public pageName!: string | null;
  public pageData?: any;
  public currentPageUrl?: string;

  public visitInProgress$ = new BehaviorSubject<VisitInProgress | null>(null);
  public navigationChanged$ = new Subject<void>();
  public reset$ = new Subject<void>();

  constructor() {
    this.reset();
  }

  public setPageInformation(info: PageInformation) {
    this.pageLevel = info.level;
    this.pageName = info.name || null;
    this.navigationChanged$.next();
  }

  public setPageData(pageData: any) {
    this.pageData = pageData;
    this.navigationChanged$.next();
  }

  public reset() {
    this.pageLevel = EPageLevel.None;
    this.pageName = null;
    this.reset$.next();
  }
}
