/// <reference types="@angular/localize" />
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const origin = location.origin;
//Load dynamic  manifest
const stringManifest = JSON.stringify({
  name: environment.APP_NAME,
  short_name: environment.APP_NAME,
  theme_color: environment.MAIN_COLOR,
  background_color: environment.MAIN_COLOR,
  display: 'standalone',
  scope: origin,
  start_url: origin,
  icons: [
    {
      src: origin + '/assets/partners/icon/72.png',
      sizes: '72x72',
      type: 'image/png',
      purpose: 'maskable any',
    },
    {
      src: origin + '/assets/partners/icon/96.png',
      sizes: '96x96',
      type: 'image/png',
      purpose: 'maskable any',
    },
    {
      src: origin + '/assets/partners/icon/128.png',
      sizes: '128x128',
      type: 'image/png',
      purpose: 'maskable any',
    },
    {
      src: origin + '/assets/partners/icon/144.png',
      sizes: '144x144',
      type: 'image/png',
      purpose: 'maskable any',
    },
    {
      src: origin + '/assets/partners/icon/152.png',
      sizes: '152x152',
      type: 'image/png',
      purpose: 'maskable any',
    },
    {
      src: origin + '/assets/partners/icon/192.png',
      sizes: '192x192',
      type: 'image/png',
      purpose: 'maskable any',
    },
    {
      src: origin + '/assets/partners/icon/384.png',
      sizes: '384x384',
      type: 'image/png',
      purpose: 'maskable any',
    },
    {
      src: origin + '/assets/partners/icon/512.png',
      sizes: '512x512',
      type: 'image/png',
      purpose: 'maskable any',
    },
  ],
});

const blob = new Blob([stringManifest], { type: 'application/json' });
const manifestURL = URL.createObjectURL(blob);
document.querySelector('#my-manifest-placeholder')?.setAttribute('href', manifestURL);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
