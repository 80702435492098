import { Component } from '@angular/core';

import { CamIconType } from '@camelot/icons';

import { BasePage } from '../../core/abstract/basePage';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundPage extends BasePage {
  constructor() {
    super();
  }

  public goToHome() {
    this._router.navigateByUrl('/');
  }

  public goBack() {
    this._location.back();
  }

  public notFoundIcon() {
    return CamIconType.NotFound;
  }
}
