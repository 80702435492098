import { Inject, Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import { map, of } from 'rxjs';

import { InputBase, InputTimePicker } from '@camelot/form-model';
import { InputDatePicker, InputDropdown, InputPanel, InputTextBox } from '@camelot/form-model';
import { CamBaseService } from '@camelot/server';
import { extractEnum, toUtcDate } from '@camelot/utils';

import { AppAbandonReasonsService } from '../../../translated-enumeration/abandon-reasons';
import { CallSuccessAction } from '../dto/call-success-action';
import { ProspectionCallResult } from '../dto/prospection-call-result';

export enum EFormSnoozeField {
  SnoozeType = 'snoozeType',
  NewDate = 'newDate',
}

export enum EFormCallField {
  ReachClient = 'reachClient',
  SuccessReachClient = 'successReachClient',
  CallMeBackMessage = 'callMeBackMessage',
  PlanVisitMessage = 'planVisitMessage',
  NoThanksMessage = 'noThanksMessage',
  NewDate = 'newDate',
  NewPlanDate = 'newPlanDate',
  NewPlanTime = 'newPlanTime',
}

export enum ESnoozeDuration {
  SnoozeOneDay,
  SnoozeTwoDays,
  SnoozeFiveDays,
  NewDate,
}

@Injectable({
  providedIn: 'root',
})
export class AppProspectionCtaFormService extends CamBaseService {
  constructor(
    @Inject(AppAbandonReasonsService)
    private _abandonReasonsService: AppAbandonReasonsService
  ) {
    super();
    this._registerSubscription(this._abandonReasonsService.fetchAbandonReasons().subscribe());
  }

  public getSnoozeForm(): InputBase<any>[] {
    const dropdownSnoozeType = new InputDropdown({
      key: EFormSnoozeField.SnoozeType,
      label: 'form.communication-handler.cta.snooze.snoozeType.label',
      validators: [Validators.required],
      options: of(this._getListOfSnooze()),
    });

    return [
      new InputPanel({
        key: 'panel',
        label: 'form.communication-handler.cta.snooze.label',
        contentClass: ['highlight-title'],
        children: [
          dropdownSnoozeType,
          new InputPanel({
            key: 'panel',
            label: '',
            visible$: dropdownSnoozeType.changeValue$.pipe(
              map(value => value !== null && Number(value) === ESnoozeDuration.NewDate)
            ),
            children: [
              new InputDatePicker({
                key: EFormSnoozeField.NewDate,
                label: 'form.communication-handler.cta.snooze.datepicker.label',
                minDate: 'today',
                class: 'pt-8',
              }),
            ],
          }),
        ],
      }),
    ];
  }

  public getCallForm(): InputBase<any>[] {
    const dropdownSuccessReachClient = new InputDropdown({
      key: EFormCallField.SuccessReachClient,
      label: 'form.communication-handler.cta.call.success-reach-client.label',
      options: of(this._getCallSuccessAction()),
      validators: [Validators.required],
    });

    return [
      new InputPanel({
        key: 'panel',
        label: 'form.communication-handler.cta.call.label',
        contentClass: ['highlight-title'],
        children: [
          new InputPanel({
            key: 'panel',
            label: '',
            children: [dropdownSuccessReachClient],
          }),
          new InputPanel({
            key: 'panel',
            label: '',
            visible$: dropdownSuccessReachClient.changeValue$.pipe(
              map(value => value !== null && Number(value) === CallSuccessAction.CallMeBack)
            ),
            children: [
              new InputTextBox({
                key: EFormCallField.CallMeBackMessage,
                label: 'form.communication-handler.cta.call.call-me-back.label',
                type: 'text',
              }),
              new InputDatePicker({
                key: EFormCallField.NewDate,
                label: 'form.communication-handler.cta.call.call-me-back.datepicker',
                minDate: 'today',
              }),
            ],
          }),
          new InputPanel({
            key: 'panel',
            label: '',
            visible$: dropdownSuccessReachClient.changeValue$.pipe(
              map(value => value !== null && Number(value) === CallSuccessAction.PlanVisit)
            ),
            children: [
              new InputDatePicker({
                key: EFormCallField.NewPlanDate,
                label: 'form.communication-handler.cta.call.plan-visit.datepicker',
                minDate: 'today',
              }),
              new InputTimePicker({
                key: EFormCallField.NewPlanTime,
                label: 'form.communication-handler.cta.call.plan-visit.timepicker',
              }),
            ],
          }),
          new InputPanel({
            key: 'panel',
            label: '',
            visible$: dropdownSuccessReachClient.changeValue$.pipe(
              map(value => value !== null && Number(value) === CallSuccessAction.NoThanks)
            ),
            children: [
              new InputDropdown({
                key: EFormCallField.NoThanksMessage,
                label: 'form.communication-handler.cta.call.no-thanks.label',
                options: this._abandonReasonsService.getAbandonReasons$.pipe(
                  map(reasons =>
                    reasons.map(reason => ({
                      id: reason.id.toString(),
                      name: reason.translatedValue,
                    }))
                  )
                ),
              }),
            ],
          }),
        ],
      }),
    ];
  }

  public formatCallResult(data: any): ProspectionCallResult {
    const request: ProspectionCallResult = {
      isSuccess: true,
    };
    request.callSuccessAction = Number(data[EFormCallField.SuccessReachClient]);
    switch (request.callSuccessAction) {
      case CallSuccessAction.CallMeBack:
        request.callResultDate = toUtcDate(data[EFormCallField.NewDate]);
        request.comment = data[EFormCallField.CallMeBackMessage];
        break;
      case CallSuccessAction.PlanVisit:
        const existingDate = new Date(data[EFormCallField.NewPlanDate]);
        const [hour, min] = data[EFormCallField.NewPlanTime].split(':').map(Number);
        existingDate.setHours(existingDate.getHours() + hour);
        existingDate.setMinutes(existingDate.getMinutes() + min);
        request.callResultDate = existingDate;
        break;
      case CallSuccessAction.NoThanks:
        request.abandonReason = data[EFormCallField.NoThanksMessage];
        break;
    }

    return request;
  }

  private _getListOfSnooze() {
    return extractEnum(ESnoozeDuration).map(item => ({
      id: item.value.toString(),
      name: `form.communication-handler.cta.snooze.dropdown.snoozeType.options.${item.name.toLocaleLowerCase()}`,
    }));
  }

  private _getCallSuccessAction() {
    return extractEnum(CallSuccessAction).map(item => ({
      id: item.value.toString(),
      name: `form.communication-handler.cta.call.success-reach-client.options.${item.name.toLocaleLowerCase()}`,
    }));
  }
}
