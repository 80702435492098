<cam-card (click)="this.cardSelected()" [fullHeight]="true">
  <cam-card-content>
    <div class="space-between">
      <div>
        <cam-card-tag class="tags mb-xs">
          <cam-badge
            class="badge-container"
            *ngIf="this.isNotToday"
            [value]="this.prospectionDate(this.prospection.date | date : 'shortDate')"
            type="info"
          ></cam-badge>
          <cam-material-icon *ngIf="this.isProspectionDone" class="status-icon"> check_circle </cam-material-icon>
        </cam-card-tag>
        <cam-card-title>{{ this.title }}</cam-card-title>
      </div>
      <div>
        <cam-badge
          [value]="this.getStatusTranslation(this.prospection)"
          [type]="this.getStatusType(this.prospection)"
        ></cam-badge>
      </div>
    </div>
  </cam-card-content>
</cam-card>
