import { Injectable } from '@angular/core';

import { filter, tap } from 'rxjs/operators';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BehaviorSubject } from 'rxjs';

import { Request } from '@camelot/server';
import { CamBaseService } from '@camelot/server';
import { MappingApiType } from '@camelot/server';

import { UserProfile } from './dto/user-profile';

const apiRoutes: MappingApiType = {
  GetUserProfile: {
    type: 'GET',
    url: '{ApiUrl}/UserProfile',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppUserProfileService extends CamBaseService {
  public getUserProfile$ = new BehaviorSubject<UserProfile | null>(null);

  constructor(private _gtmService: GoogleTagManagerService) {
    super(apiRoutes);
  }

  /*
   ** @deprecated
   */
  public fetchUserProfile() {
    return this._serverService.request<UserProfile>(new Request({ type: 'GetUserProfile' })).pipe(
      filter(data => !!data),
      tap(data => {
        this.getUserProfile$.next(data);
      }),
      tap(data => {
        this._gtmService.pushTag({
          event: 'userProfile',
          userProfile: {
            id: data.id,
            lastName: data.lastName,
            firstName: data.firstName,
          },
        });
      })
    );
  }
}
