import { Component, Input, inject } from '@angular/core';

import { Observable, Subject, catchError, tap } from 'rxjs';

import { ENotificationCode } from '@camelot/notification';

import { RemindersForCta } from 'src/app/models/reminders/types';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { AppReminderCtaFormService } from 'src/app/services/clientFollowUp/reminders/form/reminder-cta-form.service';
import { AppReminderCtaService } from 'src/app/services/clientFollowUp/reminders/reminder-cta.service';

@Component({
  selector: 'app-reminder-mail-template',
  templateUrl: './reminder-mail-template.component.html',
  styleUrls: ['./reminder-mail-template.component.scss'],
})
export class ReminderMailTemplateComponent extends BaseComponent {
  @Input()
  reminders!: RemindersForCta;

  @Input()
  formSubmit$?: Subject<null>;

  get remindersList() {
    return this.reminders.map(reminder => ({
      ...reminder,
      ...{ id: reminder.reminder.id },
    }));
  }
  public ctaService: {
    mail: (id: number) => Observable<any>;
  };

  protected _remindersCtaService = inject(AppReminderCtaService);
  protected _rhForm = inject(AppReminderCtaFormService);

  constructor() {
    super();
    this.ctaService = {
      mail: (id: number) =>
        this._remindersCtaService.mail(id).pipe(
          catchError((err, obs$) => {
            this._notificationService.addNotification(err, ENotificationCode.error);
            return obs$;
          }),
          tap(() => {
            this._notificationService.addNotification(
              'communication-handler.notification.success',
              ENotificationCode.success
            );
          })
        ),
    };
  }
}
