import { Injectable } from '@angular/core';

import { Request } from '@camelot/server';
import { MappingApiType } from '@camelot/server';
import { CamBaseService } from '@camelot/server';

import { ProspectionCallResult } from './dto/prospection-call-result';
import { ProspectionSnooze } from './dto/prospection-snooze';

const apiRoutes: MappingApiType = {
  Snooze: {
    type: 'POST',
    url: '{ApiUrl}/prospections/{prospectionId}/snooze',
  },
  SendMail: {
    type: 'POST',
    url: '{ApiUrl}/prospections/{prospectionId}/mail',
  },
  CallBack: {
    type: 'POST',
    url: '{ApiUrl}/prospections/{prospectionId}/call',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppProspectionCtaService extends CamBaseService {
  constructor() {
    super(apiRoutes);
  }

  public snooze(id: number, request: ProspectionSnooze) {
    return this._serverService.request(
      new Request({
        type: 'Snooze',
        content: { ...request, prospectionId: id },
      })
    );
  }

  public mail(id: number) {
    return this._serverService.request(new Request({ type: 'SendMail', content: { prospectionId: id } }));
  }

  public callBack(id: number, request: ProspectionCallResult) {
    return this._serverService.request(new Request({ type: 'CallBack', content: { ...request, prospectionId: id } }));
  }
}
