import { Component, Input } from '@angular/core';

import { sendMail } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';

@Component({
  selector: 'app-mail-card',
  templateUrl: './mail-card.component.html',
  styleUrls: ['./mail-card.component.scss'],
})
export class MailCardComponent extends BaseComponent {
  @Input()
  mail!: string;

  @Input()
  withButton!: boolean;

  constructor() {
    super();
  }

  public sendEmail() {
    sendMail(this.mail);
  }
}
