import { BadgeType } from '@camelot/ui';

import { ProspectionStatus } from 'src/app/services/clientFollowUp/prospections/dto/prospection-status';
import { PriceOfferStatus } from 'src/app/services/offerRequest/dto/price-offer-status';

/**
 * Status
 */
export const getPieceOfferStatusType = (priceOffer: { status: PriceOfferStatus }): BadgeType => {
  switch (priceOffer.status) {
    case PriceOfferStatus.Signed:
      return 'success';
    case PriceOfferStatus.Open:
      return 'primary';
    case PriceOfferStatus.Sent:
      return 'info';
    case PriceOfferStatus.Cancelled:
      return 'danger';
    default:
      return 'info';
  }
};

export const getProspectionStatusType = (prospection: { status: ProspectionStatus }): BadgeType => {
  switch (prospection.status) {
    case ProspectionStatus.Done:
      return 'success';
    case ProspectionStatus.ToDo:
      return 'primary';
    case ProspectionStatus.Delayed:
      return 'warning';
    default:
      return 'danger';
  }
};
