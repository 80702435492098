import { Component } from '@angular/core';

import { Permissions } from '@camelot/user';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teams-support',
  templateUrl: './teams-support.component.html',
  styleUrls: ['./teams-support.component.scss'],
})
export class TeamsSupportComponent extends BaseComponent {
  get advisorSupportLink() {
    return environment.SUPPORTURL;
  }

  get canDisplayAdvisorLink() {
    return this.advisorSupportLink && Permissions.hasRole('Advisor');
  }

  public goToAdvisorSupport() {
    if (this.advisorSupportLink) {
      window.open(this.advisorSupportLink);
    }
  }

  get workerSupportLink() {
    return environment.WORKER_SUPPORTURL;
  }

  get canDisplayWorkerLink() {
    return this.advisorSupportLink && (Permissions.hasRole('Worker') || Permissions.hasRole('WorkingLeader'));
  }

  public goToWorkerSupport() {
    if (this.workerSupportLink) {
      window.open(this.workerSupportLink);
    }
  }
}
