import { Component } from '@angular/core';

import { CamUserService } from '@camelot/user';

@Component({
  selector: 'app-disconnected-card',
  templateUrl: './disconnected-card.component.html',
  styleUrls: ['./disconnected-card.component.scss'],
})
export class HomeDisconnectedCardComponent {
  constructor(public userService: CamUserService) {}

  public login() {
    this.userService.login();
  }
}
