<cam-layout-with-bottom-nav>
  <div class="container">
    <cam-local-icon [type]="this.icon.WorkInProgress" size="xl"></cam-local-icon>

    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 go-back">
      <div class="col">
        <cam-card>
          <cam-card-content>
            <cam-button (action)="this.goToHome()">
              {{ 'base.coming-soon.cta' | translate }}
            </cam-button>
          </cam-card-content>
        </cam-card>
      </div>
    </div>
  </div>

  <cam-layout-nav>
    <app-menu-main></app-menu-main>
  </cam-layout-nav>
</cam-layout-with-bottom-nav>
