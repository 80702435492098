import { Component, Input } from '@angular/core';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { PriceOfferLight } from 'src/app/services/offerRequest/dto/price-offer-light';

@Component({
  selector: 'app-price-offer-date-list',
  templateUrl: './price-offer-date-list.component.html',
  styleUrls: ['./price-offer-date-list.component.scss'],
})
export class PriceOfferDateListComponent extends BaseComponent {
  @Input()
  priceOffer!: PriceOfferLight;

  constructor() {
    super();
  }

  public getAllDate(): { label: string; value: string }[] {
    const listOfDates = [];

    if (this.priceOffer.visitDate) {
      listOfDates.push({
        label: 'base.price-offer.date-list.visits-date',
        value: this.priceOffer.visitDate,
      });
    }
    if (this.priceOffer.lastPriceOfferVersionDate) {
      listOfDates.push({
        label: 'base.price-offer.date-list.last-price-date',
        value: this.priceOffer.lastPriceOfferVersionDate,
      });
    }

    if (this.priceOffer.previousReminderDate) {
      listOfDates.push({
        label: 'base.price-offer.date-list.reminder-date',
        value: this.priceOffer.previousReminderDate as string,
      });
    }

    return listOfDates;
  }
}
