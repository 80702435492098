<div class="content-container">
  <ng-container [ngSwitch]="this.type">
    <ng-container *ngSwitchCase="'Phone'">
      <app-reminder-call-template [reminders]="this.reminders" [formSubmit$]="this.formSubmit$">
      </app-reminder-call-template>
    </ng-container>

    <ng-container *ngSwitchCase="'Mail'">
      <app-reminder-mail-template [reminders]="this.reminders" [formSubmit$]="this.formSubmit$">
      </app-reminder-mail-template>
    </ng-container>

    <ng-container *ngSwitchCase="'Snooze'">
      <app-reminder-snooze-template [reminders]="this.reminders" [formSubmit$]="this.formSubmit$">
      </app-reminder-snooze-template>
    </ng-container>

    <ng-container *ngSwitchCase="'Return'">
      <app-reminder-return-template [reminders]="this.reminders" [formSubmit$]="this.formSubmit$">
      </app-reminder-return-template>
    </ng-container>
  </ng-container>
</div>
