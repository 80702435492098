import { Component } from '@angular/core';

import { downloadFile } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-installation',
  templateUrl: './download-installation.component.html',
  styleUrls: ['./download-installation.component.scss'],
})
export class DownloadInstallationComponent extends BaseComponent {
  getIosLink() {
    return environment.INSTALLATION_URL.IOS;
  }
  getAndroidLink() {
    return environment.INSTALLATION_URL.ANDROID;
  }
  public appStore() {
    downloadFile(this.getIosLink());
  }

  public playStore() {
    downloadFile(this.getAndroidLink());
  }
}
