<ng-container *ngIf="this.mail">
  <cam-contact-information [value]="'base.card.mail.title'" [icon]="'mail'">
    <div class="space-between">
      <div class="my-a">
        {{ this.mail }}
      </div>
      <div *ngIf="this.withButton">
        <cam-button (action)="this.sendEmail()" [options]="{ circular: 'small' }">
          <cam-font-icon name="mail" type="sm"></cam-font-icon>
        </cam-button>
      </div>
    </div>
  </cam-contact-information>
</ng-container>
