import { Injectable } from '@angular/core';

import { CamBaseService, MappingApiType, Request } from '@camelot/server';

import { VisitInProgress } from '../visits/dto/visit-in-progress';

const apiRoutes: MappingApiType = {
  GetVisitInProgress: {
    type: 'GET',
    url: '{ApiUrl}/visits/inprogress',
  },
};

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends CamBaseService {
  constructor() {
    super(apiRoutes);
  }

  public fetchVisitInProgress$() {
    return this._serverService.request<VisitInProgress>(new Request({ type: 'GetVisitInProgress' }));
  }
}
