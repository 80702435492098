import { Component, Input, inject } from '@angular/core';

import { Observable, Subject, catchError, tap } from 'rxjs';

import { InputBase } from '@camelot/form-model';
import { ENotificationCode } from '@camelot/notification';

import { RemindersForCta } from 'src/app/models/reminders/types';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { AppReminderCtaFormService } from 'src/app/services/clientFollowUp/reminders/form/reminder-cta-form.service';
import { AppReminderCtaService } from 'src/app/services/clientFollowUp/reminders/reminder-cta.service';

@Component({
  selector: 'app-reminder-snooze-template',
  templateUrl: './reminder-snooze-template.component.html',
  styleUrls: ['./reminder-snooze-template.component.scss'],
})
export class ReminderSnoozeTemplateComponent extends BaseComponent {
  @Input()
  reminders!: RemindersForCta;

  @Input()
  formSubmit$?: Subject<null>;

  get remindersList() {
    return this.reminders.map(reminder => ({
      ...reminder,
      ...{ id: reminder.reminder.id },
    }));
  }
  public ctaService: {
    getSnoozeForm: () => InputBase<any>[];
    snooze: (id: number, data: { date: Date }) => Observable<any>;
  };

  protected _remindersCtaService = inject(AppReminderCtaService);
  protected _rhForm = inject(AppReminderCtaFormService);

  constructor() {
    super();
    this.ctaService = {
      getSnoozeForm: () => this._rhForm.getSnoozeForm(),
      snooze: (id: number, request: { date: Date }) =>
        this._remindersCtaService.snooze(id, { newReminderDate: request.date }).pipe(
          catchError((err, obs$) => {
            this._notificationService.addNotification(err, ENotificationCode.error);
            return obs$;
          }),
          tap(() => {
            this._notificationService.addNotification(
              'communication-handler.notification.success',
              ENotificationCode.success
            );
          })
        ),
    };
  }
}
