<cam-progress-bar-data
  [current]="(this.currentRemindersDone$ | async) ?? 0"
  [max]="(this.totalReminders$ | async) ?? 0"
  [title]="'base.home.reminders.title'"
  [description]="'base.home.reminders.by-day'"
></cam-progress-bar-data>

<cam-loader [isLoading]="this.requestState.isLoading()" skeleton="cardList">
  <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
    <cam-empty
      [icon]="this.icon.Checked"
      [text]="'base.home.reminders.no-reminder'"
      [isEmpty]="(this.dailyReminders$ | async)?.length === 0"
    >
      <cam-swiper-light
        class="swiper"
        containerClasses="row"
        [items]="(this.dailyReminders$ | async)!"
        [template]="swipeTemplate"
      ></cam-swiper-light>

      <ng-template #swipeTemplate let-element="element" [typedTemplate]="typeToken">
        <app-reminder-card-base
          class="reminder-card col-12 col-sm-6 col-xl-3"
          [reminder]="element"
        ></app-reminder-card-base>
      </ng-template>
    </cam-empty>
  </cam-error>
</cam-loader>
