import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CommentEntity } from 'src/app/services/common/dto/comment';

@Component({
  selector: 'app-comments-item',
  templateUrl: './comments-item.component.html',
  styleUrls: ['./comments-item.component.scss'],
})
export class CommentsItemComponent {
  @Input()
  comment!: CommentEntity;

  @Output()
  commentClicked: EventEmitter<CommentEntity> = new EventEmitter<CommentEntity>();

  public onCommentClicked(): void {
    this.commentClicked.emit(this.comment);
  }
}
