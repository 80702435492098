<div class="content-container">
  <ng-container [ngSwitch]="this.type">
    <ng-container *ngSwitchCase="'Phone'">
      <app-prospection-call-template [prospections]="this.prospections" [formSubmit$]="this.formSubmit$">
      </app-prospection-call-template>
    </ng-container>

    <ng-container *ngSwitchCase="'Mail'">
      <app-prospection-mail-template [prospections]="this.prospections" [formSubmit$]="this.formSubmit$">
      </app-prospection-mail-template>
    </ng-container>

    <ng-container *ngSwitchCase="'Snooze'">
      <app-prospection-snooze-template [prospections]="this.prospections" [formSubmit$]="this.formSubmit$">
      </app-prospection-snooze-template>
    </ng-container>
  </ng-container>
</div>
