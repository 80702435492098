import { Component } from '@angular/core';

import { EMainRoute } from 'src/app/models/routes/eRoutes';
import { RouteConfiguration } from 'src/app/models/routes/routeConfiguration';

import { BasePage } from '../../core/abstract/basePage';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
})
export class ComingSoonPage extends BasePage {
  constructor() {
    super();
  }

  public goToHome() {
    this._router.navigateByUrl(RouteConfiguration.getAbsoluteUrl([EMainRoute.HOME]));
  }
}
