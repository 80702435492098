import { Component, Input, inject } from '@angular/core';

import { Observable, Subject, catchError, tap } from 'rxjs';

import { InputBase } from '@camelot/form-model';
import { ENotificationCode } from '@camelot/notification';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { ProspectionLight } from 'src/app/services/clientFollowUp/prospections/dto/prospection-light';
import { AppProspectionCtaFormService } from 'src/app/services/clientFollowUp/prospections/form/prospection-cta-form.service';
import { AppProspectionCtaService } from 'src/app/services/clientFollowUp/prospections/prospection-cta.service';

@Component({
  selector: 'app-prospection-snooze-template',
  templateUrl: './prospection-snooze-template.component.html',
  styleUrls: ['./prospection-snooze-template.component.scss'],
})
export class ProspectionSnoozeTemplateComponent extends BaseComponent {
  @Input()
  prospections!: ProspectionLight[];

  @Input()
  formSubmit$?: Subject<null>;

  public ctaService: {
    getSnoozeForm: () => InputBase<any>[];
    snooze: (id: number, data: { date: Date }) => Observable<any>;
  };

  protected _prospectionsCtaService = inject(AppProspectionCtaService);
  protected _rhForm = inject(AppProspectionCtaFormService);

  constructor() {
    super();
    this.ctaService = {
      getSnoozeForm: () => this._rhForm.getSnoozeForm(),
      snooze: (id: number, request: { date: Date }) =>
        this._prospectionsCtaService.snooze(id, { newProspectionDate: request.date }).pipe(
          catchError((err, obs$) => {
            this._notificationService.addNotification(err, ENotificationCode.error);
            return obs$;
          }),
          tap(() => {
            this._notificationService.addNotification(
              'communication-handler.notification.success',
              ENotificationCode.success
            );
          })
        ),
    };
  }
}
