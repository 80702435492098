import { Component, Input } from '@angular/core';

import { User } from '@camelot/services';
import { call, fullName, sendMail } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { Department } from 'src/app/services/common/dto/department';
import { ContactPerson } from 'src/app/services/persons/dto/contactPerson';
import { Person } from 'src/app/services/persons/dto/person';
import { PersonLight } from 'src/app/services/persons/dto/personLight';

@Component({
  selector: 'app-person-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class PersonCardComponent extends BaseComponent {
  @Input()
  highlight: boolean = false;

  @Input()
  person!: User | PersonLight | Person | ContactPerson;

  @Input()
  showCallButton: boolean = false;

  @Input()
  showPhone: boolean = true;

  @Input()
  summaryInformation: boolean = false;

  get fullName() {
    return this.person.naming ? fullName(this.person.naming) : '';
  }
  constructor() {
    super();
  }

  public getPhone(): string | null {
    return this.person.phoneNumber || null;
  }
  public getDepartments(): Department[] | null {
    return this.person.hasOwnProperty('departments') ? (<User>this.person).departments : [];
  }

  public getFunction(): string | null {
    return this.person.hasOwnProperty('functionInCompany') ? (<ContactPerson>this.person).functionInCompany : null;
  }

  public sendMail() {
    if (!this.person.email) {
      return;
    }
    sendMail(this.person.email);
  }
  public launchCall() {
    const phone = this.getPhone();
    if (!phone) {
      return;
    }
    call(phone);
  }
}
