import { Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appOnRender]',
})
export class OnRenderDirective implements OnChanges {
  @Input() appOnRender!: boolean;

  @Output() rendered = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['appOnRender'].previousValue !== changes['appOnRender'].currentValue) {
      this.rendered.emit();
    }
  }
}
