<ng-container *ngIf="this.phoneNumber">
  <cam-contact-information [value]="'base.card.phone.title'" [icon]="'phone'">
    <div class="space-between">
      <div class="my-a">
        {{ this.phoneNumber }}
      </div>
      <div *ngIf="this.withButton">
        <cam-button (action)="this.call(this.phoneNumber)" [options]="{ circular: 'small' }">
          <cam-font-icon name="phone" type="sm"></cam-font-icon>
        </cam-button>
      </div>
    </div>
  </cam-contact-information>
</ng-container>
