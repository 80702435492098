import { Component } from '@angular/core';

import { Menu } from '@camelot/menu';
import { Permissions } from '@camelot/user';

import { EPageLevel } from 'src/app/core/navigation/navigation.service';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { AppMenuMainService } from 'src/app/services/menus/main.service';

@Component({
  selector: 'app-menu-main',
  templateUrl: './menu-main.component.html',
  styleUrls: ['./menu-main.component.scss'],
})
export class MenuMainComponent extends BaseComponent {
  public menu = this._getMenu();

  get visible() {
    return (
      this._navigationService.pageLevel === EPageLevel.Main || this._navigationService.pageLevel === EPageLevel.None
    );
  }
  constructor(private _menuService: AppMenuMainService) {
    super();

    this._registerSubscription(Permissions.updated$.subscribe(() => this._getMenu()));
  }

  private _getMenu(): Menu {
    return this._menuService.getMenu();
  }
}
