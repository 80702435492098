import { Inject, Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import { map, of } from 'rxjs';

import { InputBase } from '@camelot/form-model';
import { InputDatePicker, InputDropdown, InputPanel, InputTextBox } from '@camelot/form-model';
import { CamBaseService } from '@camelot/server';
import { extractEnum, toUtcDate } from '@camelot/utils';

import { AppAbandonReasonsService } from '../../../translated-enumeration/abandon-reasons';
import { WorkingSiteReminderCallResult } from '../dto/workingsite-reminder-call-result';
import { WorkingSiteReminderCallSuccessAction } from '../dto/workingsite-reminder-call-success-action';

export enum EFormSnoozeField {
  SnoozeType = 'snoozeType',
  NewDate = 'newDate',
}

export enum EFormCallField {
  ReachClient = 'reachClient',
  SuccessReachClient = 'successReachClient',
  CallMeBackMessage = 'callMeBackMessage',
  PlanVisitMessage = 'planVisitMessage',
  NoThanksMessage = 'noThanksMessage',
  NewDate = 'newDate',
  NewPlanDate = 'newPlanDate',
  NewPlanTime = 'newPlanTime',
}

export enum ESnoozeDuration {
  SnoozeOneDay,
  SnoozeTwoDays,
  SnoozeFiveDays,
  NewDate,
}

@Injectable({
  providedIn: 'root',
})
export class AppWorkingSiteReminderCtaFormService extends CamBaseService {
  constructor(
    @Inject(AppAbandonReasonsService)
    private _abandonReasonsService: AppAbandonReasonsService
  ) {
    super();
    this._registerSubscription(this._abandonReasonsService.fetchAbandonReasons().subscribe());
  }

  public getSnoozeForm(): InputBase<any>[] {
    const dropdownSnoozeType = new InputDropdown({
      key: EFormSnoozeField.SnoozeType,
      label: 'form.communication-handler.cta.snooze.snoozeType.label',
      validators: [Validators.required],
      options: of(this._getListOfSnooze()),
    });

    return [
      new InputPanel({
        key: 'panel',
        label: 'form.communication-handler.cta.snooze.label',
        contentClass: ['highlight-title'],
        children: [
          dropdownSnoozeType,
          new InputPanel({
            key: 'panel',
            label: '',
            visible$: dropdownSnoozeType.changeValue$.pipe(
              map(value => value !== null && Number(value) === ESnoozeDuration.NewDate)
            ),
            children: [
              new InputDatePicker({
                key: EFormSnoozeField.NewDate,
                label: 'form.communication-handler.cta.snooze.datepicker.label',
                minDate: 'today',
                class: 'pt-8',
              }),
            ],
          }),
        ],
      }),
    ];
  }

  public getCallForm(): InputBase<any>[] {
    const dropdownSuccessReachClient = new InputDropdown({
      key: EFormCallField.SuccessReachClient,
      label: 'form.communication-handler.cta.call.success-reach-client.label',
      options: of(this._getCallSuccessAction()),
      validators: [Validators.required],
    });

    return [
      new InputPanel({
        key: 'panel',
        label: 'form.communication-handler.cta.call.label',
        contentClass: ['highlight-title'],
        children: [
          new InputPanel({
            key: 'panel',
            label: '',
            children: [dropdownSuccessReachClient],
          }),
          new InputPanel({
            key: 'panel',
            label: '',
            visible$: dropdownSuccessReachClient.changeValue$.pipe(
              map(value => value !== null && Number(value) === WorkingSiteReminderCallSuccessAction.CallMeBack)
            ),
            children: [
              new InputTextBox({
                key: EFormCallField.CallMeBackMessage,
                label: 'form.communication-handler.cta.call.call-me-back.label',
                type: 'text',
              }),
              new InputDatePicker({
                key: EFormCallField.NewDate,
                label: 'form.communication-handler.cta.call.call-me-back.datepicker',
                minDate: 'today',
              }),
            ],
          }),
        ],
      }),
    ];
  }

  public formatCallResult(data: any): WorkingSiteReminderCallResult {
    const request: WorkingSiteReminderCallResult = {
      isSuccess: true,
    };
    request.action = Number(data[EFormCallField.SuccessReachClient]);
    switch (request.action) {
      case WorkingSiteReminderCallSuccessAction.CallMeBack:
        request.newReminderDate = toUtcDate(data[EFormCallField.NewDate]);
        request.comment = data[EFormCallField.CallMeBackMessage];
        break;
      case WorkingSiteReminderCallSuccessAction.Close:
        break;
    }

    return request;
  }

  private _getListOfSnooze() {
    return extractEnum(ESnoozeDuration).map(item => ({
      id: item.value.toString(),
      name: `form.communication-handler.cta.snooze.dropdown.snoozeType.options.${item.name.toLocaleLowerCase()}`,
    }));
  }

  private _getCallSuccessAction() {
    return extractEnum(WorkingSiteReminderCallSuccessAction).map(item => ({
      id: item.value.toString(),
      name: `form.communication-handler.cta.call.success-reach-client.options.${item.name.toLocaleLowerCase()}`,
    }));
  }
}
