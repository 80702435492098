import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';

import { map } from 'rxjs/operators';

import { Status } from '../../../../../services/visits/dto/status';
import { AppVisitsService } from '../../../../../services/visits/visits.service';
import { BaseComponent } from '../../../../core/abstract/baseComponent';

@Component({
  selector: 'app-daily-visits',
  templateUrl: './daily-visits.component.html',
  styleUrls: ['./daily-visits.component.scss'],
})
export class DailyVisitsComponent extends BaseComponent {
  get visits$() {
    return this._visitsService.getByDay$(new Date()).pipe(
      map(visits =>
        visits
          .map(visit => {
            return {
              ...visit,
              key: visit.id.toString(),
            };
          })
          .sort((a, b) => {
            if (a.status === b.status) {
              return 0;
            }

            if (a.status === Status.InProgress) {
              return -1;
            } else if (a.status === Status.Done) {
              return 1;
            }

            if (b.status === Status.InProgress) {
              return 1;
            } else if (b.status === Status.Done) {
              return -1;
            }

            return 0;
          })
      )
    );
  }

  get teamVisits$() {
    return this.visits$.pipe(map(visits => visits.filter(visit => visit.team)));
  }

  get myVisits$() {
    return this.visits$.pipe(map(visits => visits.filter(visit => !visit.team)));
  }

  get currentTeamVisitsDone$() {
    return this.teamVisits$.pipe(map(visits => visits.filter(visit => visit.status === Status.Done).length));
  }

  get currentVisitsDone$() {
    return this.myVisits$.pipe(map(visits => visits.filter(visit => visit.status === Status.Done).length));
  }

  constructor(private _visitsService: AppVisitsService) {
    super();

    this._visitsService.fetchVisitsByDay$(new Date(new Date())).subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }
}
