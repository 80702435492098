import { BadgeType } from '@camelot/ui';

import { ReminderStatus } from 'src/app/services/clientFollowUp/reminders/dto/reminder-status';

/**
 * Status
 */
export const getWorkingSiteReminderStatusType = (reminder: { status: ReminderStatus }): BadgeType => {
  switch (reminder.status) {
    case ReminderStatus.Done:
      return 'success';
    case ReminderStatus.ToDo:
      return 'primary';
    case ReminderStatus?.Delayed:
      return 'warning';
    default:
      return 'danger';
  }
};
