import { Component, Input } from '@angular/core';

import { differenceInCalendarDays } from 'date-fns';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { Prospection } from 'src/app/services/clientFollowUp/prospections/dto/prospection';
import { ReminderLight } from 'src/app/services/clientFollowUp/reminders/dto/reminder-light';

type ReminderStatus = 'today' | 'ahead' | 'above';

@Component({
  selector: 'app-reminder-reminding-date',
  templateUrl: './reminder-reminding-date.component.html',
  styleUrls: ['./reminder-reminding-date.component.scss'],
})
export class ReminderRemindingDateComponent extends BaseComponent {
  @Input()
  reminder!: ReminderLight | Prospection;

  public getReminderStatus(): ReminderStatus {
    const diffDays = differenceInCalendarDays(new Date(this.reminder.date), new Date());
    if (diffDays === 0) {
      return 'today';
    }
    if (diffDays < 0) {
      return 'above';
    }
    return 'ahead';
  }
}
