<cam-card>
  <cam-card-header>
    <cam-card-title>
      {{ this.workingSite.name }}
    </cam-card-title>

    <cam-card-subtitle>
      {{ this.workingSite.translatedStatus.translatedValue }}
    </cam-card-subtitle>
  </cam-card-header>

  <cam-card-content>
    <div class="row">
      <cam-departments
        [departments]="[this.workingSite.departments]"
        [professions]="this.professions"
      ></cam-departments>
    </div>
  </cam-card-content>

  <cam-card-cta>
    <div class="content address">
      <cam-font-icon name="pin"></cam-font-icon>

      <div class="street">
        {{ this.street }}
        <div *ngIf="this.workingSite.address.postalCode && this.workingSite.address.locality">
          {{ this.workingSite.address.postalCode }}, {{ this.workingSite.address.locality }}
        </div>
      </div>
    </div>
  </cam-card-cta>
</cam-card>
