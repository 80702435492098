import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { Subject } from 'rxjs';

import { BottomSheetTemplateGenericComponent, BottomSheetTemplateGenericParams } from '@camelot/menu';
import { IProfileData } from '@camelot/ui';

import { RemindersForCta } from 'src/app/models/reminders/types';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { Prospection } from 'src/app/services/clientFollowUp/prospections/dto/prospection';
import { PersonLight } from 'src/app/services/persons/dto/personLight';

import { EMainRoute, ETierRoute } from '../../../../../models/routes/eRoutes';
import { RouteConfiguration } from '../../../../../models/routes/routeConfiguration';
import { TemplateType } from '../../dialog/reminder-dialog.component';

@Component({
  selector: 'app-reminder-card-full',
  templateUrl: './reminder-card-full.component.html',
  styleUrls: ['./reminder-card-full.component.scss'],
})
export class ReminderCardFullComponent extends BaseComponent {
  @Input()
  reminders!: RemindersForCta;

  @Input()
  prospections!: Prospection[];

  @Input()
  person!: PersonLight;

  @Output()
  askChanged = new EventEmitter<void>();

  @ViewChild('remindersTemplate', { read: TemplateRef })
  remindersTemplate!: TemplateRef<void>;

  @ViewChild('prospectionsTemplate', { read: TemplateRef })
  prospectionsTemplate!: TemplateRef<void>;

  public formSubmit$ = new Subject<null>();

  get profile(): IProfileData {
    return {
      title: {
        main: this.person.naming?.name ?? '',
        second: this.person.naming?.firstName ?? '',
      },
      phoneNumber: this.person.phoneNumber ?? undefined,
    };
  }

  constructor(private _bottomSheet: MatBottomSheet) {
    super();

    this._registerSubscription(this.formSubmit$.subscribe(this.closeBottom));
  }

  public launchedCall() {
    this._openBottom('Phone');
  }

  public launchClientReturn() {
    this._openBottom('Return');
  }

  public goToClient() {
    this._router.navigateByUrl(
      RouteConfiguration.getAbsoluteUrl([EMainRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.DETAIL], {
        id: this.person.id,
      })
    );
  }

  public closeBottom = () => {
    this._bottomSheet.dismiss();
    this.askChanged.emit();
  };

  private _openBottom(type: TemplateType) {
    const template = this._getTemplate();

    if (!template) {
      return;
    }
    this._bottomSheet.open<BottomSheetTemplateGenericComponent, BottomSheetTemplateGenericParams>(
      BottomSheetTemplateGenericComponent,
      {
        data: {
          template: template,
          context: {
            type: type,
          },
        },
      }
    );
  }

  private _getTemplate() {
    if (this.reminders && this.reminders.length > 0) {
      return this.remindersTemplate;
    }
    if (this.prospections && this.prospections.length > 0) {
      return this.prospectionsTemplate;
    }
    return null;
  }
}
