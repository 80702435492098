import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EMainRoute } from './models/routes/eRoutes';
import { RouteConfiguration } from './models/routes/routeConfiguration';
import { ComingSoonPage } from './modules/base/coming-soon/coming-soon.component';
import { HomePage } from './modules/base/home/home.component';
import { NotFoundPage } from './modules/base/not-found/not-found.component';

const routes: Routes = [
  {
    path: RouteConfiguration.getUrl([EMainRoute.HOME]),
    component: HomePage,
  },
  {
    path: RouteConfiguration.getUrl([EMainRoute.TIER]),
    loadChildren: () => import('./modules/tiers/tiers.module').then(m => m.AppTiersModule),
  },
  {
    path: RouteConfiguration.getUrl([EMainRoute.VISIT]),
    loadChildren: () => import('./modules/visits/visits.module').then(m => m.AppVisitsModule),
  },
  {
    path: RouteConfiguration.getUrl([EMainRoute.REMINDER]),
    loadChildren: () => import('./modules/reminders/reminders.module').then(m => m.AppRemindersModule),
  },
  {
    path: RouteConfiguration.getUrl([EMainRoute.OFFERREQUEST]),
    loadChildren: () => import('./modules/offer-request/offer-request.module').then(m => m.AppOfferRequestModule),
  },
  {
    path: RouteConfiguration.getUrl([EMainRoute.WORKINGSITE]),
    loadChildren: () => import('./modules/working-site/working-site.module').then(m => m.AppWorkingSiteModule),
  },
  {
    path: RouteConfiguration.getUrl([EMainRoute.CHECKLIST]),
    loadChildren: () => import('./modules/checklists/checklists.module').then(m => m.AppChecklistsModule),
  },
  {
    path: RouteConfiguration.getUrl([EMainRoute.TASK]),
    loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.AppTasksModule),
  },
  {
    path: RouteConfiguration.getUrl([EMainRoute.STOCK]),
    loadChildren: () => import('./modules/stock/van/van.module').then(m => m.AppVanModule),
  },
  {
    path: RouteConfiguration.getUrl([EMainRoute.COMINGSOON]),
    component: ComingSoonPage,
  },
  {
    path: '**',
    component: NotFoundPage,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
