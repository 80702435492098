import { Component, Input } from '@angular/core';

import { EMainRoute, ETierRoute } from 'src/app/models/routes/eRoutes';
import { RouteConfiguration } from 'src/app/models/routes/routeConfiguration';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { ContactPerson } from 'src/app/services/persons/dto/contactPerson';

@Component({
  selector: 'app-contacts-card',
  templateUrl: './contacts-card.component.html',
  styleUrls: ['./contacts-card.component.scss'],
})
export class ContactsCardComponent extends BaseComponent {
  @Input()
  contacts!: ContactPerson[];

  get contactsInOrder() {
    return this.contacts.sort((a, b) => (a.isDefaultContact > b.isDefaultContact ? -1 : 1));
  }

  constructor() {
    super();
  }

  public goToContact(contact: ContactPerson) {
    this._router.navigateByUrl(
      RouteConfiguration.getAbsoluteUrl([EMainRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.DETAIL], {
        id: contact.id,
      })
    );
  }
}
