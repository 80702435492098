import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

import { map } from 'rxjs/operators';

import { Observable, combineLatest } from 'rxjs';

import { Menu } from '@camelot/menu';
import { Logger } from '@camelot/server';
import { FileData, FileStructure, TemporaryFile } from '@camelot/utils';

import { ENotificationCode } from 'src/app/core/enum-type/notification';
import { FilterHelper } from 'src/app/models/filter-helper';
import { Picture } from 'src/app/services/files/dto/picture';

import { BaseComponent } from '../../core/abstract/baseComponent';

@Component({
  selector: 'app-pics',
  templateUrl: './pics.component.html',
  styleUrls: ['./pics.component.scss'],
})
export class PicsComponent extends BaseComponent implements OnInit {
  @Input()
  getPics$!: Observable<Picture[]>;

  @Input()
  upload!: (pics: FileStructure[]) => Observable<Picture[]>;

  @Input()
  update!: (id: number, pic: FileStructure) => Observable<Picture[]>;

  @Input()
  delete!: (pic: Picture) => Observable<Picture[]>;

  public tempFiles = new TemporaryFile();

  public viewMode: 'list' | 'view' | 'edit' = 'list';
  public currentIndex: number = 0;

  public menu: Menu;

  public filterHelper = new FilterHelper([
    { label: 'pics.mine', defaultOpen: false },
    { label: 'pics.all', defaultOpen: true },
  ]);

  constructor() {
    super();
    this.menu = this.filterHelper.getMenu();
  }

  ngOnInit(): void {
    this._registerSubscription(
      combineLatest({
        all: this._filteredPics$('all'),
        mine: this._filteredPics$('mine'),
      }).subscribe(data => {
        this._updateMenu({ all: data.all.length, mine: data.mine.length });
      })
    );
  }

  public pics$() {
    return this._filteredPics$(this.filterHelper.filter).pipe(
      map(visitPictures =>
        visitPictures.map<FileData>(visitPicture => {
          return {
            id: visitPicture.id,
            url: visitPicture.url,
            thumbnailUrl: visitPicture.thumbnailUrl,
            type: 'Image',
          };
        })
      )
    );
  }

  private _filteredPics$(filter: string) {
    return this.getPics$.pipe(
      map(visitPictures =>
        visitPictures.filter(photo => {
          if (filter === 'all') return true;
          if (filter === 'mine' && photo.isOwner) return true;
          if (filter === 'others' && !photo.isOwner) return true;
          return false;
        })
      )
    );
  }

  public uploadPics(pics: FileStructure[]) {
    this.tempFiles.addFiles(pics);

    this.listPics();
    this.upload(pics).subscribe({
      complete: () => {
        this._notificationService.addNotification('pics.upload.success.message', ENotificationCode.success);
        this.tempFiles.removeFiles(pics);
      },
      error: (error: HttpErrorResponse) => {
        this._notificationService.addNotification('pics.upload.error.message', ENotificationCode.error);
        this.tempFiles.removeFiles(pics);
        Logger.LogError(error.statusText, error);
      },
    });
  }

  public updatePics(data: { file: FileStructure; pic: Picture }) {
    if (!data.file.file) {
      return;
    }
    this.tempFiles.addFiles([data.file]);

    this.listPics();

    this.update(data.pic.id, data.file).subscribe({
      complete: () => {
        this._notificationService.addNotification('pics.update.success.message', ENotificationCode.success);
        this.tempFiles.removeFiles([data.file]);
      },
      error: (error: HttpErrorResponse) => {
        this._notificationService.addNotification('pics.update.error.message', ENotificationCode.error);
        this.tempFiles.removeFiles([data.file]);
        Logger.LogError(error.statusText, error);
      },
    });
  }

  public deletePic(pic: Picture) {
    this.requestState.asked();
    this.listPics();

    this.delete(pic).subscribe({
      complete: () => {
        this.requestState.completed();
        this._notificationService.addNotification('pics.delete.success.message', ENotificationCode.success);
      },
      error: (error: HttpErrorResponse) => {
        this.requestState.completed();

        this._notificationService.addNotification('pics.delete.error.message', ENotificationCode.success);
        Logger.LogError(error.statusText, error);
      },
    });
  }

  public viewPic(file: FileData & { index: number }) {
    this.currentIndex = file.index;
    this.viewMode = 'view';
  }

  public listPics() {
    this.currentIndex = 0;
    this.viewMode = 'list';
  }

  private _updateMenu(data: { all: number; mine: number }) {
    this.filterHelper.updateMenuDatas([
      { key: 'pics.mine', options: { notificationBadge: { label: data.mine } } },
      { key: 'pics.all', options: { notificationBadge: { label: data.all } } },
    ]);
    this.menu = this.filterHelper.getMenu();
  }
}
