import { Component, Input, inject } from '@angular/core';

import { Subject, catchError, tap } from 'rxjs';

import { ENotificationCode } from '@camelot/notification';

import { RemindersForCta } from 'src/app/models/reminders/types';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { ReminderCallResult } from 'src/app/services/clientFollowUp/reminders/dto/call-result-request';
import { AppReminderCtaFormService } from 'src/app/services/clientFollowUp/reminders/form/reminder-cta-form.service';
import { AppReminderCtaService } from 'src/app/services/clientFollowUp/reminders/reminder-cta.service';

@Component({
  selector: 'app-reminder-return-template',
  templateUrl: './reminder-return-template.component.html',
  styleUrls: ['./reminder-return-template.component.scss'],
})
export class ReminderReturnTemplateComponent extends BaseComponent {
  @Input()
  reminders!: RemindersForCta;

  @Input()
  formSubmit$?: Subject<null>;

  get remindersList() {
    return this.reminders.map(reminder => ({
      ...reminder,
      ...{ id: reminder.reminder.id },
    }));
  }

  public ctaService: {
    returnBack: (id: number, request: { isSuccess: boolean }) => any;
  };

  public formService: {
    getReturnForm: () => any;
    formatReturnResult: (data: any) => { isSuccess: boolean };
  };

  protected _remindersCtaService = inject(AppReminderCtaService);
  protected _rhForm = inject(AppReminderCtaFormService);

  constructor() {
    super();
    this.ctaService = {
      returnBack: (id: number, request: ReminderCallResult) =>
        this._remindersCtaService.callBack(id, request).pipe(
          catchError((err, obs$) => {
            this._notificationService.addNotification(err, ENotificationCode.error);
            return obs$;
          }),
          tap(() => {
            this._notificationService.addNotification(
              'communication-handler.notification.success',
              ENotificationCode.success
            );
          })
        ),
    };

    this.formService = {
      getReturnForm: () => this._rhForm.getCallForm(),
      formatReturnResult: (data: any) => this._rhForm.formatCallResult(data),
    };
  }
}
