import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

import { map } from 'rxjs/operators';

import { differenceInMinutes } from 'date-fns';
import { Observable, Subject, of } from 'rxjs';

import { BottomSheetData, BottomSheetTemplateBasicComponent, BottomSheetTemplateBasicParams } from '@camelot/menu';
import { openModal } from '@camelot/ui';

import { CommentsGroup } from 'src/app/models/comments/types';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { CommentEntity } from 'src/app/services/common/dto/comment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss'],
})
export class CommentsListComponent extends BaseComponent implements OnInit, AfterViewChecked {
  @Input() comments$!: Observable<CommentEntity[]>;

  @Input()
  postComment!: (message: string) => Observable<null>;
  @Input()
  updateComment!: (comment: CommentEntity) => Observable<null>;
  @Input()
  toggleImportance!: (comment: CommentEntity) => Observable<null>;
  @Input()
  deleteComment!: (comment: CommentEntity) => Observable<null>;

  @ViewChild('commentsContainer', { static: false }) commentsContainer!: ElementRef<HTMLElement>;
  @ViewChild('commentsList', { static: false }) commentsList!: ElementRef<HTMLElement>;

  public blockComments$: Observable<CommentsGroup[]> | null = null;

  public resetNewComment$: Subject<void> = new Subject<void>();
  public enableEditMode$: Subject<string> = new Subject<string>();

  get canDisplayNoComments$(): Observable<boolean> {
    return this.comments$.pipe(map(comments => comments.length == 0));
  }

  get isInEditMode() {
    return !!this._commentInEdit;
  }

  private _commentInEdit: CommentEntity | null = null;

  constructor(private _bottomSheet: MatBottomSheet, private _dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.blockComments$ = this.comments$.pipe(
      map(list =>
        list.reduce<CommentsGroup[]>((acc, item) => {
          const lastGroup = acc[acc.length - 1];
          if (
            lastGroup &&
            lastGroup.user.id === item.user.id &&
            this._areCommentsNear(lastGroup.lastDate, item.createdDate)
          ) {
            lastGroup.comments.push(item);
            lastGroup.lastDate = item.createdDate;

            return acc;
          }

          acc.push({
            comments: [item],
            user: item.user,
            createdDate: item.createdDate,
            isOwner: item.isOwner,
            lastDate: item.createdDate,
          });

          return acc;
        }, [])
      )
    );
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  public askPostComment(commentMessage: string) {
    if (this.isInEditMode && this._commentInEdit) {
      this.updateComment({ ...this._commentInEdit, text: commentMessage }).subscribe();
      this._commentInEdit = null;
    } else {
      this.postComment(commentMessage).subscribe();
    }
    this.resetNewComment$.next();
  }

  public cancelEditMode() {
    this._commentInEdit = null;
    this.resetNewComment$.next();
  }

  public openBottomSheet(comment: CommentEntity) {
    if (this._canModifyComment(comment)) {
      this._bottomSheet.open<BottomSheetTemplateBasicComponent, BottomSheetTemplateBasicParams>(
        BottomSheetTemplateBasicComponent,
        {
          data: {
            orientation: 'horizontal',
            menu$: of(this._getBottomSheetActions(comment)),
          },
        }
      );
    }
  }

  public scrollToBottom() {
    this.commentsContainer.nativeElement.scrollTop = this.commentsList.nativeElement.clientHeight;
  }

  private _areCommentsNear(date1String: string, date2String: string) {
    const date1 = new Date(date1String);
    const date2 = new Date(date2String);

    return Math.abs(differenceInMinutes(date1, date2)) < environment.components.comments.delayBetween;
  }

  private _getBottomSheetActions(comment: CommentEntity): BottomSheetData[] {
    return [
      {
        label: this._getToggleImportanceLabel(comment.isImportant),
        icon: 'warning',
        action: () => this._askToggleImportance(comment),
      },
      {
        label: 'comments.list.edit',
        icon: 'modify',
        action: () => this._setEditCommentMode(comment),
      },
      {
        label: 'comments.list.delete',
        icon: 'delete',
        action: () => this._askDeleteComment(comment),
      },
    ];
  }

  private _setEditCommentMode(comment: CommentEntity) {
    this.enableEditMode$.next(comment.text);
    this._commentInEdit = comment;
    this._bottomSheet.dismiss(true);
  }

  private _askDeleteComment(comment: CommentEntity) {
    openModal<boolean>(this._dialog).subscribe(result => {
      if (result) this.deleteComment(comment).subscribe();
    });

    this._bottomSheet.dismiss(true);
  }

  private _askToggleImportance(comment: CommentEntity) {
    this.toggleImportance(comment).subscribe();

    this._bottomSheet.dismiss(true);
  }

  private _canModifyComment(comment: CommentEntity) {
    return comment.isOwner;
  }

  private _getToggleImportanceLabel(isImportant: boolean) {
    return isImportant ? 'comments.list.put-no-important' : 'comments.list.put-important';
  }
}
