import { Injectable } from '@angular/core';

import { Request } from '@camelot/server';
import { MappingApiType } from '@camelot/server';
import { CamBaseService } from '@camelot/server';

import { ReminderCallResult } from './dto/call-result-request';
import { SnoozeRequest } from './dto/snooze-request';

const apiRoutes: MappingApiType = {
  Snooze: {
    type: 'POST',
    url: '{ApiUrl}/reminders/{reminderId}/snooze',
  },
  SendMail: {
    type: 'POST',
    url: '{ApiUrl}/reminders/{reminderId}/mail',
  },
  CallBack: {
    type: 'POST',
    url: '{ApiUrl}/reminders/{reminderId}/call',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppReminderCtaService extends CamBaseService {
  constructor() {
    super(apiRoutes);
  }

  public snooze(id: number, request: SnoozeRequest) {
    return this._serverService.request(
      new Request({
        type: 'Snooze',
        content: { ...request, reminderId: id },
      })
    );
  }

  public mail(id: number) {
    return this._serverService.request(new Request({ type: 'SendMail', content: { reminderId: id } }));
  }

  public callBack(id: number, request: ReminderCallResult) {
    return this._serverService.request(new Request({ type: 'CallBack', content: { ...request, reminderId: id } }));
  }
}
