import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';

import { map } from 'rxjs/operators';

import { of } from 'rxjs';

import { Reminder } from '../../../../../services/clientFollowUp/reminders/dto/reminder';
import { ReminderStatus } from '../../../../../services/clientFollowUp/reminders/dto/reminder-status';
import { AppRemindersService } from '../../../../../services/clientFollowUp/reminders/reminders.service';
import { BaseComponent } from '../../../../core/abstract/baseComponent';

@Component({
  selector: 'app-daily-reminders',
  templateUrl: './daily-reminders.component.html',
  styleUrls: ['./daily-reminders.component.scss'],
})
export class DailyRemindersComponent extends BaseComponent {
  public typeToken!: { element: Reminder };

  get dailyReminders$() {
    return this._remindersService.reminders$().pipe(
      map((reminders: Reminder[]) =>
        reminders.map(reminder => {
          return {
            ...reminder,
            key: reminder.id.toString(),
            visible$: of(true),
          };
        })
      )
    );
  }

  get totalReminders$() {
    return this.dailyReminders$.pipe(map(reminders => reminders.length));
  }

  get currentRemindersDone$() {
    return this.dailyReminders$.pipe(
      map(reminders => reminders.filter(reminder => reminder.status === ReminderStatus.Done).length)
    );
  }

  constructor(private _remindersService: AppRemindersService) {
    super();

    this.requestState.asked();
    this._remindersService.fetch().subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }
}
