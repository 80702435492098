<div class="fit-content pic-view-container">
  <div *ngIf="!this.isEditMode">
    <div class="menu" *ngIf="this.currentPic.isOwner">
      <cam-button (action)="this.action()">Action</cam-button>
    </div>
    <cam-swiper
      [slidesPerPage]="1"
      [isFreeMode]="false"
      [swipeTemplate]="view"
      [slides]="this.slides"
      [startAt]="this.currentIndex"
      (onSlideChanged)="this.changeCurrentId($event)"
    >
    </cam-swiper>
  </div>

  <div class="edit" *ngIf="this.isEditMode && this.currentPic">
    <cam-files-edit
      [imagePath]="this.currentPic.url"
      [saveImage$]="this.askImage$"
      (savedImage)="this.savedImage($event)"
    >
    </cam-files-edit>
  </div>
</div>

<ng-template #view let-element="element" [typedTemplate]="typeToken">
  <div class="show">
    <div class="image-container">
      <img [src]="element.url" />
    </div>
  </div>
</ng-template>
