<div class="page-container">
  <cam-pwa></cam-pwa>
  <cam-notification-box></cam-notification-box>

  <div class="page-content">
    <main class="main-content">
      <router-outlet></router-outlet>
    </main>
  </div>

  <div class="tui-image-loader"></div>
</div>
