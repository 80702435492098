<cam-card
  [highlight]="this.highlight"
  [ngClass]="this.cardClass"
  [fullHeight]="this.fullHeight"
  (click)="this.cardSelected()"
>
  <cam-card-header>
    <cam-card-tag>
      <div class="tag">
        <div class="planned-hours">
          {{ this.visitByDay.startDate | date : 'shortTime' }} -
          {{ this.visitByDay.endDate | date : 'shortTime' }}
        </div>
        <div class="badge">
          <cam-badge [value]="this.visitTypeTranslation" [type]="this.visitTypeType"></cam-badge>
        </div>
      </div>
    </cam-card-tag>
    <cam-card-title>{{ this.visitName }}</cam-card-title>
  </cam-card-header>
  <cam-card-content>
    <div class="row g-0 address">
      <div class="col-auto mr-space">
        <cam-font-icon [name]="this.visitByDay.address ? 'pin' : 'help'"></cam-font-icon>
      </div>
      <div class="col-auto" *ngIf="this.visitByDay.address">
        <div>{{ this.visitByDay.address.street }}</div>
        <div>{{ this.visitByDay.address.postalCode }} {{ this.visitByDay.address.locality }}</div>
      </div>
    </div>
  </cam-card-content>
  <cam-card-cta>
    <div class="space-between">
      <div>
        <cam-department-icon-list [departments]="this.visitByDay.departments"></cam-department-icon-list>
      </div>

      <div class="row g-0 status-type">
        <cam-badge
          *ngIf="this.visitByDay.team"
          class="col mr-space"
          type="info"
          [value]="this.visitByDay.team.name"
        ></cam-badge>
        <cam-badge class="col" [type]="this.visitStatusType" [value]="this.visitStatusTranslation"></cam-badge>
      </div>
    </div>
  </cam-card-cta>
</cam-card>
