import { Injectable } from '@angular/core';

import { compareDesc } from 'date-fns';
import { BehaviorSubject, Observable, combineLatest, filter, map, tap } from 'rxjs';

import { Request } from '@camelot/server';
import { MappingApiType } from '@camelot/server';
import { CamBaseService } from '@camelot/server';

import { WorkingSiteReminder } from '../clientFollowUp/workingsite-reminders/dto/workingsite-reminder';
import { ProductionVoucherStatus } from './dto/production-voucher-status';
import { WorkingSite } from './dto/working-site';
import { WorkingSiteLight } from './dto/working-site-light';

const apiRoutes: MappingApiType = {
  GetWorkingSite: {
    type: 'GET',
    url: '{ApiUrl}/workingSites/{workingSiteId}',
  },
  GetWorkingSitesFromClient: {
    type: 'GET',
    url: '{ApiUrl}/workingSites/{clientId}/workingSites',
  },
  GetFilteredWorkingSites: {
    type: 'GET',
    url: '{ApiUrl}/workingSites?status={status}',
  },
  GetRemindersForWorkingSite: {
    type: 'GET',
    url: '{ApiUrl}/workingSites/{workingSiteId}/reminders',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppWorkingSiteService extends CamBaseService {
  public workingSite$ = new BehaviorSubject<{ [id: number]: WorkingSite }>({});
  public workingSitesFromClient$ = new BehaviorSubject<{ [id: number]: WorkingSiteLight[] }>({});
  public workingSites$ = new BehaviorSubject<{ [id: number]: WorkingSiteLight[] }>({});

  constructor() {
    super(apiRoutes);
  }

  public getWorkingSite$ = (workingSiteId: number): Observable<WorkingSite> =>
    this.workingSite$.pipe(
      map(data => data[workingSiteId]),
      filter(myData => !!myData)
    );

  public fetchWorkingSite$(data: { workingSiteId: number }): Observable<WorkingSite> {
    return combineLatest([
      this._serverService.request<WorkingSite>(
        new Request({
          type: 'GetWorkingSite',
          urlData: data,
        })
      ),
      this._serverService.request<WorkingSiteReminder[]>(
        new Request({
          type: 'GetRemindersForWorkingSite',
          urlData: data,
        })
      ),
    ]).pipe(
      filter(data => !!data[0]),
      map(data => {
        return { ...data[0], workingSiteReminders: data[1] };
      }),
      tap(entity => {
        const entities = this.workingSite$.getValue();
        entities[data.workingSiteId] = entity;
        this.workingSite$.next(entities);
      })
    );
  }

  public getWorkingSitesFromClient$ = (clientId: number): Observable<WorkingSiteLight[]> =>
    this.workingSitesFromClient$.pipe(
      map(data => data[clientId]),
      filter(myData => !!myData),
      map(workingSites =>
        workingSites.sort((a, b) => compareDesc(new Date(a.createdTime || ''), new Date(b.createdTime || '')))
      )
    );

  public fetchWorkingSitesFromClient$(data: { clientId: number }) {
    return this._serverService
      .request<WorkingSiteLight[]>(
        new Request({
          type: 'GetWorkingSitesFromClient',
          urlData: data,
        })
      )
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this.workingSitesFromClient$.getValue();
          entities[data.clientId] = entity;
          this.workingSitesFromClient$.next(entities);
        })
      );
  }

  public getFilteredWorkingSites$ = (status: ProductionVoucherStatus): Observable<WorkingSiteLight[]> =>
    this.workingSites$.pipe(
      map(data => data[status]),
      filter(myData => !!myData),
      map(workingSites =>
        workingSites.sort((a, b) => compareDesc(new Date(a.createdTime || ''), new Date(b.createdTime || '')))
      )
    );

  public fetchFilteredWorkingSites$(data: { status: ProductionVoucherStatus }) {
    return this._serverService
      .request<WorkingSiteLight[]>(
        new Request({
          type: 'GetFilteredWorkingSites',
          urlData: data,
        })
      )
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this.workingSites$.getValue();
          entities[data.status] = entity;
          this.workingSites$.next(entities);
        })
      );
  }
}
