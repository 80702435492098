import { AfterViewInit, Component } from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { CamDeviceInfoService, CamDeviceNetworkService, CamDevicePositionService } from '@camelot/capacitor';
import { Logger } from '@camelot/server';

import { BaseComponent } from './modules/core/abstract/baseComponent';
import { ProfileService } from './services/common/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements AfterViewInit {
  constructor(
    private _profileService: ProfileService,
    private _gtmService: GoogleTagManagerService,
    private _deviceNetworkService: CamDeviceNetworkService,
    private _devicePositionService: CamDevicePositionService,
    private _deviceInfoService: CamDeviceInfoService
  ) {
    super();

    this._userService.load();
    this._gtmService.addGtmToDom();

    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this._navigationService.reset();
        window.scrollTo(0, 0);
        return;
      }
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: event.url,
        };

        this._gtmService.pushTag(gtmTag);

        this._navigationService.currentPageUrl = this._router.url;
      }
      if (event instanceof NavigationError) {
        //
      }
    });

    this._profileService
      .fetchVisitInProgress$()
      .subscribe(visit => this._navigationService.visitInProgress$.next(visit));

    this._userProfileService.fetchUserProfile().subscribe();

    this._deviceNetworkService.observeNetworkStateChanges();
    this._devicePositionService.fetchCanAccessPosition();
    this._devicePositionService.fetchCurrentPosition();

    this.setBodyClasses();
  }

  public ngAfterViewInit() {
    try {
      // TODO
      // new ImageEditor('tui-image-loader', {});
    } catch (e) {
      Logger.LogDebug('tui-image-loader');
    }
  }

  private setBodyClasses() {
    this._deviceInfoService.deviceClasses$.subscribe(classes => {
      for (let classToAdd of classes.filter(x => x.length > 0)) document.body.classList.add(classToAdd);
    });
  }
}
