<div class="content-container">
  <ng-container [ngSwitch]="this.type">
    <ng-container *ngSwitchCase="'Phone'">
      <app-working-site-reminder-call-template
        [workingSiteReminders]="this.workingSiteReminders"
        [formSubmit$]="this.formSubmit$"
      >
      </app-working-site-reminder-call-template>
    </ng-container>

    <ng-container *ngSwitchCase="'Mail'">
      <app-working-site-reminder-mail-template
        [workingSiteReminders]="this.workingSiteReminders"
        [formSubmit$]="this.formSubmit$"
      >
      </app-working-site-reminder-mail-template>
    </ng-container>

    <ng-container *ngSwitchCase="'Snooze'">
      <app-working-site-reminder-snooze-template
        [workingSiteReminders]="this.workingSiteReminders"
        [formSubmit$]="this.formSubmit$"
      >
      </app-working-site-reminder-snooze-template>
    </ng-container>
  </ng-container>
</div>
