import { Component, Input } from '@angular/core';

import { User } from '@camelot/services/dist/lib/services/common/dto/user';
import { UserLogoNaming } from '@camelot/ui';
import { capitalizeFirstLetter } from '@camelot/utils';

import { WorkingSiteLight } from 'src/app/services/workingSite/dto/working-site-light';

@Component({
  selector: 'app-card-working-site',
  templateUrl: './card-working-site.component.html',
  styleUrls: ['./card-working-site.component.scss'],
})
export class WorkingSiteComponent {
  @Input() workingSite!: WorkingSiteLight;

  get professions(): string[] {
    return this.workingSite.professions.map(workingSite => workingSite.translatedValue);
  }

  get street(): string {
    return capitalizeFirstLetter(this.workingSite.address.street || '');
  }

  public userInfo(advisor: User): { profilePictureUrl?: string; naming: UserLogoNaming | null } {
    return {
      naming: advisor.naming,
      profilePictureUrl: '',
    };
  }

  public trigram(advisor: User): string {
    return advisor.naming?.trigram || '';
  }
}
