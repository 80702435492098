<cam-card-content>
  <div class="list row row-gap-3">
    <div *ngFor="let contact of this.contactsInOrder" class="detail">
      <app-person-card [person]="contact" [highlight]="contact.isDefaultContact">
        <div class="goToCard">
          <cam-link (click)="this.goToContact(contact)">
            {{ 'base.card.contact.cta' | translate }}
          </cam-link>
        </div>
      </app-person-card>
    </div>
  </div>
</cam-card-content>
