import { Component, Input } from '@angular/core';

import { Subject } from 'rxjs';

import { RemindersForCta } from 'src/app/models/reminders/types';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';

export type TemplateType = 'Phone' | 'Mail' | 'Snooze' | 'Return';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-reminder-dialog',
  templateUrl: './reminder-dialog.component.html',
  styleUrls: ['./reminder-dialog.component.scss'],
})
export class ReminderBottomModal extends BaseComponent {
  @Input()
  type!: TemplateType;

  @Input()
  reminders!: RemindersForCta;

  @Input()
  formSubmit$!: Subject<null>;

  constructor() {
    super();
  }
}
