import { Injectable } from '@angular/core';

import { filter, tap } from 'rxjs/operators';

import { Request } from '@camelot/server';
import { CamBaseService } from '@camelot/server';
import { MappingApiType } from '@camelot/server';
import { ReplaySubject } from 'rxjs';

import { Dashboard } from './dto/dashboard';

const apiRoutes: MappingApiType = {
  GetDashboard: {
    type: 'GET',
    url: '{ApiUrl}/dashboard',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppDashboardService extends CamBaseService {
  private _getDashboard$ = new ReplaySubject<Dashboard>(1);

  constructor() {
    super(apiRoutes);
  }

  public getDashboard$ = this._getDashboard$.asObservable();

  public fetchDashboard() {
    return this._serverService.request<Dashboard>(new Request({ type: 'GetDashboard', cacheTime: 0 })).pipe(
      filter(data => !!data),
      tap(data => {
        this._getDashboard$.next(data);
      })
    );
  }
}
