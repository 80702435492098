import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CommentsGroup } from 'src/app/models/comments/types';
import { CommentEntity } from 'src/app/services/common/dto/comment';

@Component({
  selector: 'app-comments-group',
  templateUrl: './comments-group.component.html',
  styleUrls: ['./comments-group.component.scss'],
})
export class CommentsGroupComponent {
  @Input()
  group!: CommentsGroup;

  @Output()
  commentClicked: EventEmitter<CommentEntity> = new EventEmitter<CommentEntity>();

  get userInfo() {
    return {
      naming: this.group.user.naming,
    };
  }

  get author(): string {
    return this.group.user?.naming?.firstName ?? '';
  }

  constructor() {}

  public onCommentClicked(comment: CommentEntity): void {
    this.commentClicked.emit(comment);
  }
}
