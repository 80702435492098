<mat-toolbar class="header">
  <div class="row mX-0">
    <div class="col-2 align-items-center">
      <ng-container *ngIf="this.showBack">
        <div class="align-items-center" (click)="this.goBack()">
          <cam-material-icon>chevron_left</cam-material-icon>
        </div>
      </ng-container>
      <ng-container *ngIf="!this.showBack">
        <div (click)="this.goToHome()" class="px-1">
          <cam-logo color="white"></cam-logo>
        </div>
      </ng-container>
    </div>
    <div class="col-7 align-items-center p-0 text-truncate">
      {{ this.title | translate }}
    </div>
    <div class="col-3 ta-r">
      <app-menu-user></app-menu-user>
    </div>
  </div>
</mat-toolbar>
