<ng-container *ngLet="(this.teamVisits$ | async) ?? [] as teamVisits">
  <div *ngIf="teamVisits && teamVisits.length" class="mb-sm">
    <cam-progress-bar-data
      [current]="(this.currentTeamVisitsDone$ | async) ?? 0"
      [max]="teamVisits.length ?? 0"
      [title]="'base.home.visit-team.title'"
      [description]="'base.home.visits.by-day'"
    ></cam-progress-bar-data>

    <cam-loader [isLoading]="this.requestState.isLoading()" skeleton="cardList">
      <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
        <cam-empty [text]="'base.home.visit-team.no-visit'" [isEmpty]="teamVisits.length === 0">
          <cam-swiper-light
            class="swiper"
            containerClasses="row"
            [items]="teamVisits"
            [template]="swipeTemplate"
          ></cam-swiper-light>
        </cam-empty>
      </cam-error>
    </cam-loader>
  </div>
</ng-container>

<ng-container *ngLet="(this.myVisits$ | async) ?? [] as myVisits">
  <cam-progress-bar-data
    [current]="(this.currentVisitsDone$ | async) ?? 0"
    [max]="myVisits.length"
    [title]="'base.home.visits.title'"
    [description]="'base.home.visits.by-day'"
  ></cam-progress-bar-data>
  <cam-loader [isLoading]="this.requestState.isLoading()" skeleton="cardList">
    <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
      <cam-empty [icon]="this.icon.Checked" [text]="'base.home.visits.no-visit'" [isEmpty]="myVisits.length === 0">
        <cam-swiper-light
          class="swiper"
          containerClasses="row"
          [template]="swipeTemplate"
          [items]="myVisits"
        ></cam-swiper-light>
      </cam-empty>
    </cam-error>
  </cam-loader>
</ng-container>

<ng-template #swipeTemplate let-element="element">
  <app-card-visit-day
    class="visit-card col-12 col-sm-6 col-xl-3"
    [highlight]="element.highlight"
    [visitByDay]="element"
    [fullHeight]="true"
  >
  </app-card-visit-day>
</ng-template>
