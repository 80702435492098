import { Component, Input } from '@angular/core';

import { Subject } from 'rxjs';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { Prospection } from 'src/app/services/clientFollowUp/prospections/dto/prospection';

export type TemplateType = 'Phone' | 'Mail' | 'Snooze';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-prospection-dialog',
  templateUrl: './prospection-dialog.component.html',
  styleUrls: ['./prospection-dialog.component.scss'],
})
export class ProspectionBottom extends BaseComponent {
  @Input()
  type!: TemplateType;

  @Input()
  prospections!: Prospection[];

  @Input()
  formSubmit$!: Subject<null>;

  constructor() {
    super();
  }
}
