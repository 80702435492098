<cam-card class="teams-support-card" *ngIf="this.canDisplayAdvisorLink || this.canDisplayWorkerLink">
  <cam-card-content>
    <cam-title class="support-title" [level]="3" [isTheme]="true">{{
      'base.home.teams-support.title' | translate
    }}</cam-title>

    <div class="teams">
      <div *ngIf="this.canDisplayAdvisorLink" (click)="this.goToAdvisorSupport()">
        <cam-title [level]="4" class="subtitle">{{ 'base.home.teams-support.advisor-title' | translate }}</cam-title>
        <cam-local-icon [type]="this.icon.TeamsSupport" [size]="'md'"></cam-local-icon>
      </div>

      <div *ngIf="this.canDisplayWorkerLink" (click)="this.goToWorkerSupport()">
        <cam-title [level]="4" class="subtitle">{{ 'base.home.teams-support.worker-title' | translate }}</cam-title>
        <cam-local-icon [type]="this.icon.TeamsSupport" [size]="'md'"></cam-local-icon>
      </div>
    </div>
  </cam-card-content>
</cam-card>
