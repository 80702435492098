import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

import { Subject, of } from 'rxjs';

import { BottomSheetTemplateBasicComponent, BottomSheetTemplateBasicParams } from '@camelot/menu';
import { SwiperData, openModal } from '@camelot/ui';
import { FileStructure, newGuid } from '@camelot/utils';

import { AbstractComponent } from 'src/app/modules/core/abstract/abstractComponent';
import { Picture } from 'src/app/services/files/dto/picture';

@Component({
  selector: 'app-pic-view',
  templateUrl: './pic-view.component.html',
  styleUrls: ['./pic-view.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PicViewComponent extends AbstractComponent {
  @Input()
  pics!: Picture[];

  @Input()
  currentIndex: number = 0;

  @Output()
  deleteAction = new EventEmitter<Picture>();

  @Output()
  updateAction = new EventEmitter<{ file: FileStructure; pic: Picture }>();

  @Output()
  typeViewChanged = new EventEmitter<boolean>();

  public isEditMode: boolean = false;
  public askImage$ = new Subject<null>();
  public typeToken!: { element: Picture };

  get slides(): SwiperData[] {
    return this.pics.map(pic => ({
      ...pic,
      ...{ key: pic.id.toString(), visible$: of(true) },
    }));
  }
  get currentPic() {
    return this.pics[this._currentId];
  }
  private _currentId: number = 0;

  constructor(private _bottomSheet: MatBottomSheet, private _dialog: MatDialog) {
    super();
  }

  public action = () => {
    this._bottomSheet.open<BottomSheetTemplateBasicComponent, BottomSheetTemplateBasicParams>(
      BottomSheetTemplateBasicComponent,
      {
        data: {
          orientation: 'horizontal',
          menu$: of([
            {
              label: 'pics.edit',
              icon: 'modify',
              action: this.showEditMode,
            },
            {
              label: 'pics.delete',
              icon: 'delete',
              action: this.deletePic,
            },
          ]),
        },
      }
    );
  };
  public deletePic = () => {
    this._registerSubscription(
      openModal<boolean>(this._dialog).subscribe((result: any) => {
        if (result) {
          this.deleteAction.emit(this.currentPic);
        }
        this._bottomSheet.dismiss();
      })
    );
  };

  public askImage() {
    this.requestState.asked();
    this.askImage$.next(null);
  }
  public changeCurrentId(index: number) {
    this._currentId = index;
  }

  public showEditMode = () => {
    this.isEditMode = true;
    this.typeViewChanged.emit(this.isEditMode);
    this._bottomSheet.dismiss();
  };

  public showViewMode = () => {
    this.isEditMode = false;
    this.typeViewChanged.emit(this.isEditMode);
  };

  public savedImage(blob: Blob) {
    if (!this.currentPic) {
      return;
    }
    const file = new File([blob], newGuid(), { type: blob.type });
    this.updateAction.emit({ file: { file, localUrl: this.currentPic.url }, pic: this.currentPic });
    this.requestState.completed();
  }
}
