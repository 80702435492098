import { Component, Input } from '@angular/core';

import { call } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';

@Component({
  selector: 'app-phones-card',
  templateUrl: './phones-card.component.html',
  styleUrls: ['./phones-card.component.scss'],
})
export class PhonesCardComponent extends BaseComponent {
  @Input()
  phoneNumber!: string | null;

  @Input()
  withButton!: boolean;

  constructor() {
    super();
  }

  public call(tel: string) {
    call(tel);
  }
}
