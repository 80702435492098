import { Component, Input, inject } from '@angular/core';

import { Subject, catchError, tap } from 'rxjs';

import { ENotificationCode } from '@camelot/notification';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { ReminderLight } from 'src/app/services/clientFollowUp/reminders/dto/reminder-light';
import { AppWorkingSiteReminderCtaFormService } from 'src/app/services/clientFollowUp/workingsite-reminders/form/working-site-reminder-cta-form.service';
import { AppWorkingSiteReminderCtaService } from 'src/app/services/clientFollowUp/workingsite-reminders/workingsite-reminder-cta.service';

@Component({
  selector: 'app-working-site-reminder-mail-template',
  templateUrl: './working-site-reminder-mail-template.component.html',
  styleUrls: ['./working-site-reminder-mail-template.component.scss'],
})
export class WorkingSiteReminderMailTemplateComponent extends BaseComponent {
  @Input()
  workingSiteReminders!: ReminderLight[];

  @Input()
  formSubmit$?: Subject<null>;

  public ctaService: {
    mail: (id: number) => any;
  };

  protected _workingSiteRemindersCtaService = inject(AppWorkingSiteReminderCtaService);
  protected _rhForm = inject(AppWorkingSiteReminderCtaFormService);

  constructor() {
    super();
    this.ctaService = {
      mail: (id: number) =>
        this._workingSiteRemindersCtaService.mail(id).pipe(
          tap(() => {
            this._notificationService.addNotification(
              'communication-handler.notification.success',
              ENotificationCode.success
            );
          }),
          catchError((err, obs$) => {
            this._notificationService.addNotification(err, ENotificationCode.error);
            return obs$;
          })
        ),
    };
  }
}
