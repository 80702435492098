import { Component, Input } from '@angular/core';

import { isToday } from 'date-fns';

import { fullName } from '@camelot/utils';

import { EMainRoute, EWorkingSiteRoute } from 'src/app/models/routes/eRoutes';
import { RouteConfiguration } from 'src/app/models/routes/routeConfiguration';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { getWorkingSiteReminderStatusType } from 'src/app/modules/working-site/helpers';
import { ReminderStatus } from 'src/app/services/clientFollowUp/reminders/dto/reminder-status';
import { WorkingSiteReminder } from 'src/app/services/clientFollowUp/workingsite-reminders/dto/workingsite-reminder';

@Component({
  selector: 'app-workingsite-reminder-card',
  templateUrl: './workingsite-reminder-card.component.html',
  styleUrls: ['./workingsite-reminder-card.component.scss'],
})
export class WorkingSiteReminderCardComponent extends BaseComponent {
  @Input() reminder!: WorkingSiteReminder;

  get title() {
    return this.reminder.workingSite?.name ?? '';
  }

  get isReminderDone() {
    return this.reminder.status === ReminderStatus.Done;
  }

  get isNotToday() {
    return !isToday(new Date(this.reminder.date));
  }

  get fullName() {
    return this.reminder.relatedClient.naming ? fullName(this.reminder.relatedClient.naming) : '';
  }

  public reminderDate(date: string | null) {
    return date?.toString() ?? '';
  }

  public cardSelected(): void {
    this._router.navigateByUrl(
      RouteConfiguration.getAbsoluteUrl([EMainRoute.WORKINGSITE, EWorkingSiteRoute.DETAIL], {
        id: this.reminder.workingSiteId,
      })
    );
  }

  public getStatusTranslation(reminder: WorkingSiteReminder) {
    return 'client-follow-up.working-site-reminder.status.' + ReminderStatus[reminder.status].toLocaleLowerCase();
  }
  public getStatusType(reminder: WorkingSiteReminder) {
    return getWorkingSiteReminderStatusType(reminder);
  }
}
