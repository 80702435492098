import { Component, Input } from '@angular/core';

import { openMap } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { AddressType } from 'src/app/services/common/dto/addessType';
import { Address } from 'src/app/services/common/dto/address';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss'],
})
export class AddressCardComponent extends BaseComponent {
  @Input()
  addresses!: Address[];

  @Input()
  withButton!: boolean;

  constructor() {
    super();
  }

  public getLabelForAddressesType(type: AddressType) {
    switch (type) {
      case AddressType.Client:
        return 'Domicile';
      case AddressType.Intervention:
        return 'Chantier';
      case AddressType.Invoicing:
        return 'Facturation';
      default:
        return '';
    }
  }

  public launchAddress(address: Address) {
    if (address.latitude && address.longitude) {
      openMap(`${address.latitude},${address.longitude}`);
    }
  }
}
