import { Injectable } from '@angular/core';

import { filter, map, tap } from 'rxjs/operators';

import { Request } from '@camelot/server';
import { CamBaseService } from '@camelot/server';
import { MappingApiType } from '@camelot/server';
import { TranslatedEnumeration } from '@camelot/services';
import { BehaviorSubject, Observable } from 'rxjs';

import { sortByTranslatedValue } from './translated-enumeration-helpers';

const apiRoutes: MappingApiType = {
  GetAbandonReasons: {
    type: 'GET',
    url: '{ApiUrl}/abandonreasons',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppAbandonReasonsService extends CamBaseService {
  public getAbandonReasons$ = new BehaviorSubject<TranslatedEnumeration[]>([]);
  constructor() {
    super(apiRoutes);
  }

  public fetchAbandonReasons(): Observable<TranslatedEnumeration[]> {
    return this._serverService
      .request<TranslatedEnumeration[]>(new Request({ type: 'GetAbandonReasons', cacheTime: -1 }))
      .pipe(
        filter(myData => !!myData),
        map(reasons => sortByTranslatedValue(reasons)),
        tap(reasons => this.getAbandonReasons$.next(reasons))
      );
  }
}
