<div class="post-container">
  <h5 class="m-0">{{ this.title | translate }}</h5>
  <div class="space-between">
    <cam-input-textarea [input]="this.comment"></cam-input-textarea>
    <cam-font-icon
      appStopPropagation
      (click)="this.onCommentPosted()"
      [name]="this.isInEditMode ? 'modify' : 'send'"
      class="my-a ml-xs"
    >
    </cam-font-icon>
  </div>
</div>
