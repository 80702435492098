import { Component, Input } from '@angular/core';

import { fullName } from '@camelot/utils';

import { EMainRoute, ETierRoute } from 'src/app/models/routes/eRoutes';
import { RouteConfiguration } from 'src/app/models/routes/routeConfiguration';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { PersonLight } from 'src/app/services/persons/dto/personLight';

@Component({
  selector: 'app-person-card-detail',
  templateUrl: './person-card-detail.component.html',
  styleUrls: ['./person-card-detail.component.scss'],
})
export class PersonCardDetailComponent extends BaseComponent {
  @Input()
  person!: PersonLight;

  get fullName(): string {
    if (this.person.naming) {
      return fullName(this.person.naming);
    }
    return '';
  }

  constructor() {
    super();
  }

  public navigateToClient() {
    this._router.navigateByUrl(
      RouteConfiguration.getAbsoluteUrl([EMainRoute.TIER, ETierRoute.EXTERNAL, ETierRoute.DETAIL], {
        id: this.person.id,
      })
    );
  }
}
