import { Component, Input } from '@angular/core';

import { Menu } from '@camelot/menu';

@Component({
  selector: 'app-menu-second',
  templateUrl: './menu-second.component.html',
  styleUrls: ['./menu-second.component.scss'],
})
export class SecondNavComponent {
  @Input()
  menu!: Menu;

  constructor() {}
}
