import { Injectable } from '@angular/core';

import { CamBaseService, MappingApiType } from '@camelot/server';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService extends CamBaseService {
  constructor(apiRoutes?: MappingApiType) {
    super(apiRoutes);
  }
}
