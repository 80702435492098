<cam-card>
  <cam-card-header>
    <cam-card-title>
      {{ this.offerRequest.name }}
    </cam-card-title>

    <cam-card-subtitle>
      {{ this.offerRequest.translatedStatus.translatedValue }}
    </cam-card-subtitle>
  </cam-card-header>

  <cam-card-content>
    <div class="row">
      <div class="col-8">
        <cam-departments
          [departments]="this.offerRequest.departments"
          [professions]="this.professions"
        ></cam-departments>
      </div>
      <div class="col-4 advisors">
        <ng-container *ngFor="let advisor of this.offerRequest.advisors; trackBy: this.trackById">
          <cam-user-logo [userInfo]="this.userInfo(advisor)"></cam-user-logo>
        </ng-container>
      </div>
    </div>
  </cam-card-content>

  <cam-card-cta>
    <div class="content">
      <div class="address">
        <cam-font-icon name="pin"></cam-font-icon>

        <div class="street">
          {{ this.street }}
        </div>

        <div class="locality" *ngIf="this.offerRequest.address.postalCode && this.offerRequest.address.locality">
          {{ this.offerRequest.address.postalCode }}, {{ this.offerRequest.address.locality }}
        </div>
      </div>
    </div>
  </cam-card-cta>
</cam-card>
