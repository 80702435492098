<div *ngIf="this.viewMode === 'list'">
  <cam-files-display
    [menu]="this.menu"
    [files$]="this.pics$()"
    [canAddFile]="!!this.upload"
    fileType="Image"
    [tempFiles]="this.tempFiles.files"
    (fileSelected)="this.viewPic($event)"
    (fileUploading)="this.uploadPics($event)"
  ></cam-files-display>
</div>
<div *ngIf="this.viewMode === 'view' || this.viewMode === 'edit'">
  <div>
    <app-pic-view
      [pics]="(this.getPics$ | async) || []"
      [currentIndex]="this.currentIndex"
      (deleteAction)="this.deletePic($event)"
      (updateAction)="this.updatePics($event)"
      (typeViewChanged)="this.viewMode = $event ? 'view' : 'edit'"
    >
    </app-pic-view>
  </div>

  <div class="ta-c mt-lg">
    <cam-button (action)="this.listPics()">{{ 'base.pics.back-to-list' | translate }}</cam-button>
  </div>
</div>
