import { Component, Input } from '@angular/core';

import { Subject } from 'rxjs';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { WorkingSiteReminder } from 'src/app/services/clientFollowUp/workingsite-reminders/dto/workingsite-reminder';

export type TemplateType = 'Phone' | 'Mail' | 'Snooze';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-working-site-reminder-dialog',
  templateUrl: './working-site-reminder-dialog.component.html',
  styleUrls: ['./working-site-reminder-dialog.component.scss'],
})
export class WorkingSiteReminderBottom extends BaseComponent {
  @Input()
  type!: TemplateType;

  @Input()
  workingSiteReminders!: WorkingSiteReminder[];

  @Input()
  formSubmit$!: Subject<null>;

  constructor() {
    super();
  }
}
