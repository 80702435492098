import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

import { EMainRoute, EWorkingSiteRoute } from 'src/app/models/routes/eRoutes';
import { RouteConfiguration } from 'src/app/models/routes/routeConfiguration';
import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { ProductionVoucherStatus } from 'src/app/services/workingSite/dto/production-voucher-status';
import { AppWorkingSiteService } from 'src/app/services/workingSite/working-site.service';

@Component({
  selector: 'app-working-site-home',
  templateUrl: './working-site-home.component.html',
  styleUrls: ['./working-site-home.component.scss'],
})
export class WorkingSiteHomeComponent extends BaseComponent implements OnInit {
  @Input()
  status!: ProductionVoucherStatus;

  get workingSites$() {
    return this._workingSitesService.getFilteredWorkingSites$(this.status);
  }

  constructor(private _workingSitesService: AppWorkingSiteService) {
    super();
  }

  ngOnInit(): void {
    this.requestState.asked();
    this._workingSitesService.fetchFilteredWorkingSites$({ status: this.status }).subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }

  public goToWorkingSite(id: number) {
    this._router.navigateByUrl(
      RouteConfiguration.getAbsoluteUrl([EMainRoute.WORKINGSITE, EWorkingSiteRoute.DETAIL], {
        id: id,
      })
    );
  }

  public setDescription(status: ProductionVoucherStatus) {
    if (status === ProductionVoucherStatus.Delayed) {
      return 'base.home.working-site.late';
    }
    if (status === ProductionVoucherStatus.ToAdjust) {
      return 'base.home.working-site.to-adjust';
    }
    return '';
  }

  public setRightText(status: ProductionVoucherStatus) {
    if (status === ProductionVoucherStatus.Delayed) {
      return {
        text: this.setDescription(this.status),
        colorClass: 'color-warning',
      };
    }
    if (status === ProductionVoucherStatus.ToAdjust) {
      return {
        text: this.setDescription(this.status),
        colorClass: 'color-danger',
      };
    }
    return {
      text: '',
    };
  }
}
