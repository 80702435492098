import { Component, OnDestroy, inject } from '@angular/core';

import { LAZY_SERVICE_TOKEN } from '@camelot/notification';
import { CamTranslationService } from '@camelot/translation';
import { CamUserService } from '@camelot/user';
import { CamAbstractComponent } from '@camelot/utils';

import { AppUserProfileService } from 'src/app/services/user-profile/user-profile.service';

import { NavigationService } from '../../../core/navigation/navigation.service';

@Component({ template: '' })
export abstract class AbstractComponent extends CamAbstractComponent implements OnDestroy {
  protected _navigationService = inject(NavigationService);
  protected _translationService = inject(CamTranslationService);
  protected _notificationService = inject(LAZY_SERVICE_TOKEN);
  protected _userProfileService = inject(AppUserProfileService);
  protected _userService = inject(CamUserService);

  constructor() {
    super();
  }

  public trackById(_: any, item: { id: number }): number {
    return item.id;
  }

  public trackByKey(_: any, item: { key: string }): string {
    return item.key;
  }
}
