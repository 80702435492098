<div class="comments-container">
  <div class="comments-list-container" #commentsContainer>
    <cam-empty [isEmpty]="(this.canDisplayNoComments$ | async)!" [icon]="this.icon.Speak">
      <div class="comments" #commentsList>
        <app-comments-group
          *ngFor="let group of this.blockComments$ | async; index as i; let last = last"
          [appOnRender]="!!last"
          (rendered)="this.scrollToBottom()"
          [group]="group"
          (commentClicked)="this.openBottomSheet($event)"
        >
        </app-comments-group>
      </div>
    </cam-empty>
  </div>
  <div class="border-container"></div>
  <div class="reply">
    <app-comments-post
      (commentPosted)="this.askPostComment($event)"
      (editionCancelled)="this.cancelEditMode()"
      [enableEditMode$]="this.enableEditMode$"
      [isInEditMode]="this.isInEditMode"
      [reset]="this.resetNewComment$"
    >
    </app-comments-post>
  </div>
</div>
