import { Component, Input, inject } from '@angular/core';

import { Subject, catchError, tap } from 'rxjs';

import { ENotificationCode } from '@camelot/notification';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { ProspectionCallResult } from 'src/app/services/clientFollowUp/prospections/dto/prospection-call-result';
import { ProspectionLight } from 'src/app/services/clientFollowUp/prospections/dto/prospection-light';
import { AppProspectionCtaFormService } from 'src/app/services/clientFollowUp/prospections/form/prospection-cta-form.service';
import { AppProspectionCtaService } from 'src/app/services/clientFollowUp/prospections/prospection-cta.service';

@Component({
  selector: 'app-prospection-call-template',
  templateUrl: './prospection-call-template.component.html',
  styleUrls: ['./prospection-call-template.component.scss'],
})
export class ProspectionCallTemplateComponent extends BaseComponent {
  @Input()
  prospections!: ProspectionLight[];

  @Input()
  formSubmit$?: Subject<null>;

  public ctaService: {
    callBack: (id: number, request: { isSuccess: boolean }) => any;
  };

  public formService: {
    getCallForm: () => any;
    formatCallResult: (data: any) => { isSuccess: boolean };
  };

  protected _prospectionsCtaService = inject(AppProspectionCtaService);
  protected _rhForm = inject(AppProspectionCtaFormService);

  constructor() {
    super();
    this.ctaService = {
      callBack: (id: number, request: ProspectionCallResult) =>
        this._prospectionsCtaService.callBack(id, request).pipe(
          catchError((err, obs$) => {
            this._notificationService.addNotification(err, ENotificationCode.error);
            return obs$;
          }),
          tap(() => {
            this._notificationService.addNotification(
              'communication-handler.notification.success',
              ENotificationCode.success
            );
          })
        ),
    };

    this.formService = {
      getCallForm: () => this._rhForm.getCallForm(),
      formatCallResult: (data: any) => this._rhForm.formatCallResult(data),
    };
  }
}
