import { Injectable } from '@angular/core';

import { Request } from '@camelot/server';
import { MappingApiType } from '@camelot/server';
import { CamBaseService } from '@camelot/server';

import { WorkingSiteReminderCallResult } from './dto/workingsite-reminder-call-result';
import { WorkingSiteReminderSnooze } from './dto/workingsite-reminder-snooze';

const apiRoutes: MappingApiType = {
  Snooze: {
    type: 'POST',
    url: '{ApiUrl}/workingsitereminder/{workingSiteReminderId}/snooze',
  },
  SendMail: {
    type: 'POST',
    url: '{ApiUrl}/workingsitereminder/{workingSiteReminderId}/mail',
  },
  CallBack: {
    type: 'POST',
    url: '{ApiUrl}/workingsitereminder/{workingSiteReminderId}/call',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppWorkingSiteReminderCtaService extends CamBaseService {
  constructor() {
    super(apiRoutes);
  }

  public snooze(id: number, request: WorkingSiteReminderSnooze) {
    return this._serverService.request(
      new Request({
        type: 'Snooze',
        content: { ...request, workingSiteReminderId: id },
      })
    );
  }

  public mail(id: number) {
    return this._serverService.request(new Request({ type: 'SendMail', content: { workingSiteReminderId: id } }));
  }

  public callBack(id: number, request: WorkingSiteReminderCallResult) {
    return this._serverService.request(
      new Request({ type: 'CallBack', content: { ...request, workingSiteReminderId: id } })
    );
  }
}
