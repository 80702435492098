import { Component, Input } from '@angular/core';

import { User } from '@camelot/services';
import { getCivility, getCivilityIcon } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { ContactPerson } from 'src/app/services/persons/dto/contactPerson';
import { Person } from 'src/app/services/persons/dto/person';
import { PersonLight } from 'src/app/services/persons/dto/personLight';
import { UserNaming } from 'src/app/services/users/dto/userNaming';

@Component({
  selector: 'app-card-person-tag-civility',
  templateUrl: './card-person-tag-civility.component.html',
  styleUrls: ['./card-person-tag-civility.component.scss'],
})
export class CardPersonTagCivilityComponent extends BaseComponent {
  @Input()
  person!: User | PersonLight | Person | ContactPerson;

  constructor() {
    super();
  }
  public getTrigram(): string | null {
    return this.person.naming && this.person.naming.hasOwnProperty('trigram')
      ? (this.person.naming as any as UserNaming).trigram
      : null;
  }

  public getCivilityIcon() {
    return getCivilityIcon(getCivility(this.person));
  }

  public haveCompanyInfo() {
    return this.person.hasOwnProperty('isCompany');
  }
  public isCompany() {
    return this.haveCompanyInfo() ? (this.person as any as PersonLight).isCompany : null;
  }
}
