<ng-container *ngLet="(this.workingSites$ | async) ?? [] as workingSites">
  <div class="mb-sm">
    <cam-progress-bar-data
      [title]="'base.home.working-site.title'"
      [rightText]="this.setRightText(this.status)"
    ></cam-progress-bar-data>

    <cam-loader [isLoading]="this.requestState.isLoading()" skeleton="cardList">
      <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
        <cam-empty [text]="'base.home.working-site.no-working'" [isEmpty]="workingSites.length === 0">
          <cam-swiper-light
            class="swiper"
            containerClasses="row"
            [items]="workingSites"
            [template]="swipeTemplate"
          ></cam-swiper-light>
        </cam-empty>
      </cam-error>
    </cam-loader>
  </div>
</ng-container>

<ng-template #swipeTemplate let-element="element">
  <app-card-working-site
    class="working-card col-12 col-sm-6 col-xl-3"
    [workingSite]="element"
    (click)="this.goToWorkingSite(element.id)"
  >
  </app-card-working-site>
</ng-template>
