import { ActivatedRouteSnapshot } from '@angular/router';

import {
  ECheckListRoute,
  EMainRoute,
  EOfferRequestRoute,
  EReminderRoute,
  EStockRoute,
  ETaskRoute,
  ETierRoute,
  EUSerRoute,
  EVisitRoute,
  EWorkingSiteRoute,
  IRoute,
} from './eRoutes';

/**
 * @deprecated
 */
export class RouteConfigurationCore {
  public routes: IRoute[] = [
    {
      key: EMainRoute.HOME,
      url: '',
    },
    {
      key: EMainRoute.COMINGSOON,
      url: 'comming-soon',
    },
    {
      key: EMainRoute.TIER,
      url: 'tiers',
      children: [
        {
          key: ETierRoute.EXTERNAL,
          url: 'external',
          children: [
            {
              key: ETierRoute.LIST,
              url: 'list',
            },
            {
              key: ETierRoute.DETAIL,
              url: 'detail/:id',
            },
            {
              key: ETierRoute.SUBDETAIL,
              url: 'detail/:id/:scope',
            },
          ],
        },
        {
          key: ETierRoute.INTERNAL,
          url: 'internal',
          children: [
            {
              key: ETierRoute.LIST,
              url: 'list',
            },
            {
              key: ETierRoute.DETAIL,
              url: 'detail/:id',
            },
          ],
        },
      ],
    },
    {
      key: EMainRoute.USER,
      url: 'user',
      children: [
        {
          key: EUSerRoute.LOGIN,
          url: 'login',
        },
      ],
    },
    {
      key: EMainRoute.VISIT,
      url: 'visits',
      children: [
        {
          key: EVisitRoute.LIST,
          url: 'list',
          children: [
            {
              key: EVisitRoute.AGENDA,
              url: 'agenda',
            },
            {
              key: EVisitRoute.CHRONOLOGICAL,
              url: 'chronological',
            },
          ],
        },
        {
          key: EVisitRoute.DETAIL,
          url: 'detail/:type/:id',
        },
        {
          key: EVisitRoute.SUBDETAIL,
          url: 'detail/:type/:id/:scope',
        },
      ],
    },
    {
      key: EMainRoute.REMINDER,
      url: 'reminders',
      children: [
        {
          key: EReminderRoute.REMINDERS,
          url: 'reminders',
        },
        {
          key: EReminderRoute.PROSPECTIONS,
          url: 'prospections',
        },
        {
          key: EReminderRoute.WORKINGSITEREMINDERS,
          url: 'working-site-reminders',
        },
      ],
    },
    {
      key: EMainRoute.OFFERREQUEST,
      url: 'offerrequest',
      children: [
        {
          key: EOfferRequestRoute.DETAIL,
          url: 'detail/:id',
        },
        {
          key: EOfferRequestRoute.SUBDETAIL,
          url: 'detail/:id/:scope',
        },
      ],
    },
    {
      key: EMainRoute.WORKINGSITE,
      url: 'workingsite',
      children: [
        {
          key: EWorkingSiteRoute.DETAIL,
          url: 'detail/:id',
        },
        {
          key: EWorkingSiteRoute.SUBDETAIL,
          url: 'detail/:id/:scope',
        },
      ],
    },
    {
      key: EMainRoute.STOCK,
      url: 'stock',
      children: [
        {
          key: EStockRoute.VAN,
          url: 'van',
        },
        {
          key: EStockRoute.VAN_DETAIL,
          url: 'van/:id',
        },
        {
          key: EStockRoute.SUBDETAIL,
          url: 'van/:id/:scope',
        },
      ],
    },
    {
      key: EMainRoute.CHECKLIST,
      url: 'checklists',
      children: [
        {
          key: ECheckListRoute.VIEW,
          url: 'view/:visitType/:visitId/:id',
        },
      ],
    },
    {
      key: EMainRoute.TASK,
      url: 'tasks',
      children: [
        {
          key: ETaskRoute.LIST,
          url: 'list',
          children: [
            {
              key: ETaskRoute.RESPONSIBLE,
              url: 'responsible',
            },
            {
              key: ETaskRoute.REQUESTER,
              url: 'requester',
            },
          ],
        },
        {
          key: ETaskRoute.DETAIL,
          url: 'detail/:id',
        },
        {
          key: ETaskRoute.SUBDETAIL,
          url: 'detail/:id/:scope',
        },
      ],
    },
  ];

  constructor() {}

  /**
   * @deprecated
   */
  public getHome() {
    return this.getAbsoluteUrl([EMainRoute.HOME]);
  }
  /**
   * @deprecated
   */
  public getLogin() {
    return this.getAbsoluteUrl([EMainRoute.USER, EUSerRoute.LOGIN]);
  }
  /**
   * @deprecated
   */
  public getUrl(eNums: number[], params: {} = {}, strict = false): string {
    const url = this._replaceParams(this._getUrl(eNums), params);
    return strict ? this._removeParams(url) : url;
  }
  /**
   * @deprecated
   */
  public getAbsoluteUrl(eNums: number[], params: {} = {}, strict = false): string {
    const url = this._replaceParams(this._getUrl(eNums, true), params);
    return strict ? this._removeParams(url) : url;
  }
  /**
   * @deprecated
   */
  public addQueryParamsToUrl(route: ActivatedRouteSnapshot, params: { [index: string]: any } = {}): string {
    const keys = Object.keys(params);

    for (let key of keys) {
      route.params[key] = params[key];
    }
    return route.toString();
  }
  /**
   * @deprecated
   */
  public getPermission(eNums: any[]): boolean {
    const route = this._getRouteByENum(eNums);
    if (route === null) {
      return true;
    } else {
      return route.canActivate === undefined ? true : route.canActivate;
    }
  }
  /**
   * @deprecated
   */
  private _replaceParams(url: string, params: any): string {
    if (!params || Object.keys(params).length === 0) {
      return url;
    }
    // Create regex using the keys of the replacement object.
    const regex = new RegExp(':(' + Object.keys(params).join('|') + ')', 'g');
    // Replace the string by the value in object
    return url.replace(regex, (m, $1) => params[$1] || m);
  }
  /**
   * @deprecated
   */
  private _removeParams(url: string): string {
    const regex = new RegExp('/:([a-zA-Z0-9_]*)', 'g');
    return url.replace(regex, '');
  }
  /**
   * @deprecated
   */
  private _getRouteByENum(eNums: any[]): IRoute | null {
    let route: IRoute | null = null;
    for (const eNum of eNums) {
      route = this._getByENum(route === null ? this.routes : route.children, eNum);
      if (route === null) {
        return null;
      }
    }
    return route;
  }
  /**
   * @deprecated
   */
  private _getUrl(eNums: any[], absolute = false): string {
    let route: IRoute | null = null;
    let url = '';
    for (const eNum of eNums) {
      route = this._getByENum(route === null ? this.routes : route.children, eNum);
      if (route === null) {
        break;
      }
      url += (url === '' ? '' : '/') + route.url;
    }
    return route === null ? '' : absolute === false ? route.url : '/' + url;
  }
  /**
   * @deprecated
   */
  private _getByENum(routes: IRoute[] | null | undefined, eNum: number): IRoute | null {
    if (!routes) {
      return null;
    }
    for (const route of routes) {
      if (route.key === eNum) {
        return route;
      }
    }
    return null;
  }
}

/**
 * @deprecated
 */
export const RouteConfiguration = new RouteConfigurationCore();
