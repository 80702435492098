<div class="comment-group" [class.mine]="this.group.isOwner">
  <div class="picture">
    <cam-user-logo [userInfo]="this.userInfo"></cam-user-logo>
  </div>

  <div>
    <div class="information">
      <span class="author">{{ this.author }}</span>

      <span class="time">
        {{ this.group.createdDate | appTimeAgo | async }}
      </span>
    </div>

    <div class="messages" *ngFor="let comment of this.group.comments">
      <app-comments-item [comment]="comment" (commentClicked)="this.onCommentClicked($event)"></app-comments-item>
    </div>
  </div>
</div>
