export interface IRoute {
  key: number;
  url: string;
  canActivate?: boolean;
  children?: IRoute[];
}

export enum EMainRoute {
  CHECKLIST,
  COMINGSOON,
  HOME,
  OFFERREQUEST,
  REMINDER,
  STOCK,
  TIER,
  USER,
  VISIT,
  TASK,
  WORKINGSITE,
}

export enum ETierRoute {
  LIST,
  EXTERNAL,
  INTERNAL,
  DETAIL,
  SUBDETAIL,
}

export enum EUSerRoute {
  LOGIN,
  DETAIL,
}

export enum EVisitRoute {
  LIST,
  AGENDA,
  CHRONOLOGICAL,
  DETAIL,
  SUBDETAIL,
}

export enum EReminderRoute {
  REMINDERS,
  PROSPECTIONS,
  WORKINGSITEREMINDERS,
}

export enum EOfferRequestRoute {
  DETAIL,
  SUBDETAIL,
}

export enum EWorkingSiteRoute {
  DETAIL,
  SUBDETAIL,
}

export enum EStockRoute {
  VAN,
  VAN_DETAIL,
  SUBDETAIL,
}

export enum ECheckListRoute {
  VIEW,
}

export enum ETaskRoute {
  LIST,
  RESPONSIBLE,
  REQUESTER,
  DETAIL,
  SUBDETAIL,
}
