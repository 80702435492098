import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthConfig, AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { CamCalendarModule } from '@camelot/calendar';
import { CamCapacitorModule, CamPwaService, PWA_CONFIG_KEY } from '@camelot/capacitor';
import { CamIconsModule } from '@camelot/icons';
import { CamNotificationModule } from '@camelot/notification';
import { CamServerModule, SERVER_CONFIG_KEY } from '@camelot/server';
import { CamTranslationModule, CamTranslationService, TRANSLATION_CONFIG } from '@camelot/translation';
import { CamUiModule } from '@camelot/ui';
import { CamUserService } from '@camelot/user';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationService } from './core/navigation/navigation.service';
import { AppBaseModule } from './modules/base/base.module';
import { AppHomeModule } from './modules/base/home/home.module';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEN, 'en');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [AppComponent],
  imports: [
    GoogleTagManagerModule.forRoot({
      id: environment.GTM,
    }),
    AppBaseModule,
    AppHomeModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CamCalendarModule,
    CamCapacitorModule,
    CamIconsModule,
    CamNotificationModule.forRoot(),
    CamServerModule,
    CamTranslationModule,
    CamUiModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.PWAACTIVE,
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first) .
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
    AuthModule.forRoot(environment.AUTH0 as AuthConfig),
    HttpClientModule,
    MatSidenavModule,
  ],
  providers: [
    {
      provide: PWA_CONFIG_KEY,
      useValue: { active: environment.PWAACTIVE },
    },
    {
      provide: SERVER_CONFIG_KEY,
      useValue: { pendindRequestId: environment.PENDINGREQUESTAPI, serverUrl: environment.SERVER_URL },
    },
    {
      provide: TRANSLATION_CONFIG,
      useValue: {
        default: environment.TRANSLATION.DEFAULT,
        supportedLanguages: environment.TRANSLATION.SUPPORTEDLANGUAGES,
      },
    },
    {
      provide: 'config_local',
      useValue: { isLocal: environment.LOCAL },
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: LOCALE_ID,
      deps: [CamTranslationService],
      useFactory: (TranslationService: CamTranslationService) => TranslationService.getLanguage(),
    },
    CamPwaService,
    CamUserService,
    NavigationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
